$color_1: #131415;
$background-color_1: #dbe6fc;
$background-color_2: #ffffff;
$background-color_3: #f5f5f5;

.lightTheme{
    .table-text-ext {
        height: 1rem;
        margin: 0;
        font-size: 0.75rem;
        color: $color_1;
        @include fontStyle;
    }
    .table-data-scroll {
        display: block;
        height: 100px;
        @include overflowY;
    }
    .table-fixed {
        width: 98% !important;
        margin: 15px;
        border-radius: 15px;
        thead {
            background-color: $background-color_1;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            border-top: 0px;
            width: 100%;
            font-size: 0.8rem;
            display: table;
            width: 100%;
            table-layout: fixed;
            th {
                vertical-align: middle !important;
                padding: 15px 14px;
            }
        }
        tbody {
            width: 100%;
            table-layout: fixed;
            display: block;
            overflow: auto;
            scroll-behavior: smooth;
            table-layout: fixed;
            height: calc(100vh - 310px);
            border: 1px solid #d4d4d4;
            @include devices(laptop_xl) {
                height: calc(100vh - 324px);
            } 
        }
        tr {
            display: table;
            width: 100%;
            table-layout: fixed;
        }
        td {
            border-top: none !important;
        }
        th {
            border-top: none !important;
        }
        tr:nth-child(even) {
            background-color: $background-color_3;
        }
    }
    .healthmonitoringTable {
        tbody {
            height: 215px;
            tr{    
                &:nth-of-type(odd) {
                    background-color: $background-color_2;
                }
                &:nth-of-type(even) {
                    background-color: $background-color_3;
                }
            }
        }
    }
    thead {
        th {
            p {
                margin-bottom: 0px;
            }
        }
    }
    .StatusSearchText {
        margin-left: 0px;
        width: 100%;
        padding-right: 10px;
        margin-top: 5px !important;
        background-color: $background-color_2;
        border-radius: 7px;
    }
    .healthmonitoringTable{
        .SearchViewInput{
            background-color: transparent;
        }
    }
    .HMTableSelect {
        height: 35px;
        width: 100%;
        border-radius: 8px;
        /* background-color: #f0f3f5; */
        border: none;
        padding-left: 8px;
        /* padding-right: 8px; */
        font-size: 13px;
        text-align: left;
        background-position: calc(100% - 10px) 16px, calc(100% - 10px) 15px, 100% 0 !important;
        margin-bottom: 0px;
        background-color: $background-color_2;
      }:focus-visible{
            outline: none;
    }
    .OnHoverMouseTableRow .no-report-found {
        height: 210px;
    }
}