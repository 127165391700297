$color_1: white;
$color_2: #818181;
$color_3: #f1f1f1;
$color_4: #FFFFFF;
$color_5: #4a595f;
$color_6: #8a8a8a;
$background-color_1: #f0f3f5;
$background-color_2: #626363;
$background-color_3: #000000;


.lightTheme{
        .main-mosaic {
            background-color: $background-color_1 !important;
        }
        .overlay {
            height: 80px !important;
            width: auto;
            display: flex;
            // margin: 20px 0px 0px 0px !important;
            position: absolute;
            top: 3%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: $color_1;
            background: #8a8f99;
            opacity: .8;
            z-index: 1000;
            border-radius: 0px 0px 25px 25px;
            // padding-bottom: 10px;
            // padding-top: 20px;
            transition: .5s ease;
            padding: 15px 10px 5px 5px;
            min-width: 38%;
            @include devices(laptop_md) {
				top: 4%;
			}
            a {
                padding: 8px;
                text-decoration: none;
                font-size: 36px;
                color: $color_2;
                display: block;
                transition: 0.3s;
                &:hover {
                    color: $color_3;
                }
                &:focus {
                    color: $color_3;
                }
            }
            .closebtn {
                position: absolute;
                top: 5px;
                right: 340px;
                font-size: 60px;
            }
        }
        .overlay-content {
            position: relative;
            top: 25%;
            width: 100%;
            text-align: center;
            margin-top: 30px;
        }
        .arrow-icon {
            display: flex;
            position: absolute;
            top: 81px;
            left: 50%;
            transform: translate(-50%, -50%);
            color: $color_1;
            z-index: 1000;
            @include devices(laptop_md) {
				top: 84px;
			}
            @include devices(laptop_sm) {
				top: 78px;
			}
            @include devices(laptop_xs) {
                top: 85px;
            }
        }
        .arrow-icon-collapse {
            top: 1% !important;
        }
        .inner-row {
            width: 100%;
            height: 100%;
            display: grid;
            /* grid-template-columns: 90px 2fr; */
            grid-template-columns: 5px 2fr;
        }
        .MosaicGrid{
            display: grid;
            grid-template-columns: auto 160px auto 2fr;
        }
        .item-align {
            display: flex;
            align-items: center;
        }
        .mosaic-popup {
            width: auto;
            padding: 0.6rem 0.2rem 0.6rem 0.6rem;
            border-radius: 8px;
            box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.0784313725);
            background-color: #626363;
            border: none;
            height: 46px;
            margin-top: 6px;
        }
        .mosaic-popup-text {
            width: auto;
            height: 1rem;
            margin: 0.063rem 1.5rem 0 0.3rem;
            font-size: 0.75rem;
            @include fontStyle;
            color: $color_4;
            @include devices(laptop_md) {
                margin: 0.063rem 1.438rem 0 0.545rem;
            }
            @include devices(laptop_sm) {
                
            }
        }
        .mosaic-popup-text-dd {
            width: 4.25rem;
            height: 1rem;
            font-size: 0.75rem;
            @include fontStyle;
            color: $color_5;
            &:after {
                position: unset;
            }
        }
        .mosaic-select {
            background-color: $background-color_2;
            color: $color_1;
            width: 70px;
            border: 0px;
            outline: 0px;
            cursor: pointer;
            font-size: 12px;
            background-position: calc(100% - 15px) 7px, calc(100% - 10px) 5px, 100% 0 !important;
        }
        .mosaic-content {
            margin-left: 10px !important;
        }
        .auto-play-text {
            width: auto;
            height: 1rem;
            margin: 0rem;
            font-size: 0.75rem;
            @include fontStyle;
            color: $color_4;
        }
        // .auto-play-row {
        //     width: 8rem !important;
        // }
        // .mosaic-pagination {
        //     width: 23.5rem !important;
        // }
        .PageOfThisText {
            margin-bottom: 0px;
            display: inline-block;
            font-size: 0.875rem;
            @include fontStyle;
            color: $color_6;
            width: 3.5rem;
        }
        .mosaic-row {
            margin: 0px !important;
            width: 100%;
        }
        .HeightChildDiv:hover {
            // background-color: rgba(0, 0, 0, 0.7) !important;
            // background-color: red !important;
        }
        .parent2x2 {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            grid-template-rows: repeat(6, 1fr);
            grid-gap: 3px;
            width: 100vw;
            height: 99.8vh;
            overflow: hidden;
            .HeightChildDiv {
                &:nth-child(1) {
                    grid-column: auto / span 3;
                    grid-row: span 3;
                }
                &:nth-child(2) {
                    grid-column: auto / span 3;
                    grid-row: span 3;
                }
                &:nth-child(3) {
                    grid-column: auto / span 3;
                    grid-row: span 3;
                }
                &:nth-child(4) {
                    grid-column: auto / span 3;
                    grid-row: span 3;
                }
            }
            :hover .mosaicButtonsMainDiv {
                display: flex;
                bottom: 1px;
            }
        }
        .parent3x3 {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            grid-template-rows: repeat(6, 1fr);
            grid-gap: 3px;
            width: 100vw;
            height: 99.8vh;
            overflow: hidden;
            .HeightChildDiv {
                &:nth-child(1) {
                    grid-column: auto / span 2;
                    grid-row: span 2;
                }
                &:nth-child(2) {
                    grid-column: auto / span 2;
                    grid-row: span 2;
                }
                &:nth-child(3) {
                    grid-column: auto / span 2;
                    grid-row: span 2;
                }
                &:nth-child(4) {
                    grid-column: auto / span 2;
                    grid-row: span 2;
                }
                &:nth-child(5) {
                    grid-column: auto / span 2;
                    grid-row: span 2;
                }
                &:nth-child(6) {
                    grid-column: auto / span 2;
                    grid-row: span 2;
                }
                &:nth-child(7) {
                    grid-column: auto / span 2;
                    grid-row: span 2;
                }
                &:nth-child(8) {
                    grid-column: auto / span 2;
                    grid-row: span 2;
                }
                &:nth-child(9) {
                    grid-column: auto / span 2;
                    grid-row: span 2;
                }
            }
            :hover .mosaicButtonsMainDiv {
                display: flex;
                bottom: 1px;
            }
        }
        .parent4x4 {
            display: grid;
            grid-template-columns: repeat(8, 1fr);
            grid-template-rows: repeat(8, 1fr);
            grid-gap: 3px;
            width: 100vw;
            height: 99.8vh;
            overflow: hidden;
            .HeightChildDiv {
                grid-column: auto / span 2;
                grid-row: span 2;
            }
            :hover .mosaicButtonsMainDiv {
                display: flex;
                bottom: 1px;
            }
        }
        .parent5x5 {
            display: grid;
            grid-template-columns: repeat(10, 1fr);
            grid-template-rows: repeat(10, 1fr);
            grid-gap: 3px;
            width: 100vw;
            height: 99.8vh;
            overflow: hidden;
            .HeightChildDiv {
                grid-column: auto / span 2;
                grid-row: span 2;
            }
            :hover .mosaicButtonsMainDiv {
                display: flex;
            }
        }

        .parent6x6 {
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            grid-template-rows: repeat(12, 1fr);
            grid-gap: 3px;
            width: 100vw;
            height: 99.8vh;
            overflow: hidden;
            .HeightChildDiv {
                grid-column: auto / span 2;
                grid-row: span 2;
            }
            :hover .mosaicButtonsMainDiv {
                display: flex;
            }
        }

        .parent7x7 {
            display: grid;
            grid-template-columns: repeat(14, 1fr);
            grid-template-rows: repeat(14, 1fr);
            grid-gap: 3px;
            width: 100vw;
            height: 99.8vh;
            overflow: hidden;
            .HeightChildDiv {
                grid-column: auto / span 2;
                grid-row: span 2;
            }
            :hover .mosaicButtonsMainDiv {
                display: flex;
            }
        }

        .MosaicCameraName {
            position: absolute;
            // bottom: 8px;
            color: $color_1;
            height: auto;
            text-align: center;
            font-size: 0.7rem;
            font-weight: bold;
            margin-bottom: 1rem;
            vertical-align: middle;
            // background: rgb(0,0,0);
            // background: linear-gradient(180deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,1) 100%);
            margin: 0px 10px;
            padding: 8px;
            border-radius: 5px;
            p {
                margin: 0px;
            }
        }
        .mosaicbuttons {
            width: 100%;
            float: left;
            display: flex;
            justify-content: flex-end;
        }
        .parent5x5CameraName {
            position: absolute;
            bottom: 6px;
            color: $color_1;
            height: auto;
            text-align: center;
            font-size: 0.7rem;
            font-weight: bold;
            margin-bottom: 1rem;
            vertical-align: middle;
            background: rgb(0,0,0);
            background: linear-gradient(180deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,1) 100%);
            margin: 0px 6px;
            padding: 5px;
            border-radius: 5px;
            p {
                margin: 0px;
            }
        }
        .CameraBackBtn {
            display: flex;
            align-items: center;
            padding-left: 0px;
        }
        .versionLabel {
            position: absolute;
            bottom: 0px;
            left: 55px;
        }
        .player-responsive1 {
            >video {
                &::-webkit-media-controls-timeline {
                    display: none;
                }
                &::-webkit-media-controls-time-remaining-display {
                    display: none;
                }
                &::-webkit-media-controls-current-time-display {
                    display: none;
                }
                &::-webkit-media-controls-play-button {
                    display: none;
                }
                &::-webkit-media-controls-mute-button {
                    display: none;
                }
                &::-webkit-media-controls-fullscreen-button {
                    display: none;
                }
            }
        }
        .player-responsive-fullScreen {
            >video {
                &::-webkit-media-controls-timeline {
                    display: none;
                }

                &::-webkit-media-controls-time-remaining-display {
                    display: none;
                }

                &::-webkit-media-controls-current-time-display {
                    display: none;
                }

                &::-webkit-media-controls-play-button {
                    display: none;
                }

                &::-webkit-media-controls-mute-button {
                    display: none;
                }
            }
        }
        .player-responsive {
            >video {
                &::-webkit-media-controls-timeline {
                    display: none;
                }

                &::-webkit-media-controls-time-remaining-display {
                    display: none;
                }

                &::-webkit-media-controls-current-time-display {
                    display: none;
                }

                &::-webkit-media-controls-play-button {
                    display: none;
                }

                &::-webkit-media-controls-mute-button {
                    display: none;
                }
                &::-webkit-media-controls-fullscreen-button {
                    display: none;
                }
            }
        }
        .player-responsive-pb {
            >video {
                &::-webkit-media-controls-timeline {
                    display: none;
                }

                &::-webkit-media-controls-time-remaining-display {
                    display: none;
                }

                &::-webkit-media-controls-current-time-display {
                    display: none;
                }

                &::-webkit-media-controls-play-button {
                    display: none;
                }

                &::-webkit-media-controls-mute-button {
                    display: none;
                }
                &::-webkit-media-controls-fullscreen-button {
                    display: none;
                }
            }
        }
        .chatbotliveplayer {
            >video {
                &::-webkit-media-controls-timeline {
                    display: none;
                }

                &::-webkit-media-controls-time-remaining-display {
                    display: none;
                }

                &::-webkit-media-controls-current-time-display {
                    display: none;
                }

                &::-webkit-media-controls-play-button {
                    display: none;
                }

                &::-webkit-media-controls-mute-button {
                    display: none;
                }
                &::-webkit-media-controls-fullscreen-button {
                    display: none;
                }
            }
        }
        .multiplaybackplayer {
            width: 100% !important;
            height: 200px !important;
            padding: 0.2rem 0.2rem;
            background-color: $background-color_3;
            >video {
                &::-webkit-media-controls-time-remaining-display {
                    display: none;
                }
                &::-webkit-media-controls-current-time-display {
                    display: none;
                }
                &::-webkit-media-controls-mute-button {
                    display: none;
                }
            }
        }
        .mosaic {
            >video {
                &::-webkit-media-controls-timeline {
                    display: none;
                }
                &::-webkit-media-controls-time-remaining-display {
                    display: none;
                }
                &::-webkit-media-controls-current-time-display {
                    display: none;
                }
                &::-webkit-media-controls-play-button {
                    display: none;
                }
                &::-webkit-media-controls-mute-button {
                    display: none;
                }
            }
        }

        .select-ext {
            border: none;
        }
                
        .mosaic-options {
            width: 26.438rem;
            height: 2.375rem;
            /* padding: 0.563rem 1.5rem 0.625rem 1.666rem; */
            border-radius: 19px;
            box-shadow: 0 3px 15px 0 #00000014;
            border: solid 1px #748b94;
            background-color: #fff;
            display: inline-flex;
        }

        .mosaic-options-text {
            /* width: 4.813rem; */
            height: 1.188rem;
            /* margin: 0 0 0 0.375rem; */
            padding: 0 0.5rem 0 0.5rem;
            font-size: 0.875rem;
            @include fontStyle;
            color: #4e4e4e;
            margin: auto 0;
        }
        .paginationm-move {
            line-height: 0 !important;
        }
        
        .common-pagination {
            display: flex;
            align-items: center;
            margin-right: 65rem !important;
        }
        
        .titleIcon {
            position: relative;
            top: -1px;
        }
        .mosaic-container {
            position: relative;
            width: 100%;
        }
        
        .mosaic-container img {
            width: 100%;
            height: auto;
        }
        
        .mosaic-container .btn-group {
            position: absolute;
            top: 90%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            -ms-transform: translate(-50%, -50%);
        }
        .mosaic-container .btn:hover {
            background-color: black;
        }
        .sync-replay-text {
            cursor: pointer;
        }  
        .MossaicPopupDiv {
            width: 6rem;
            height: 18.688rem;
            padding: 1.188rem 0.746rem 1.076rem;
            border-radius: 12px;
            box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
            background-color: #fff;
            margin-left: 50px;
            margin-top: 30px;
        }

        .TextPopup {
            margin-bottom: 0px;
            font-size: 0.813rem;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #182e37;
        }

        .popupList {
            height: 45px;
            @include flexCenter;
            align-content: center;
            border-bottom: 1px solid #EAEAEC;
        }

        .SelectTextPopup {
            margin-bottom: 0px;
            font-size: 0.813rem;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #027aaa;
        }

        /* style the items (options), including the selected item: */

        .select-items div,
        .select-selected {
            color: #ffffff;
            padding: 8px 16px;
            border: 1px solid transparent;
            border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
            cursor: pointer;
        }

        /* Style items (options): */

        .select-items {
            position: absolute;
            background-color: DodgerBlue;
            top: 100%;
            left: 0;
            right: 0;
            z-index: 99;
        }

        .back-arrow {
            /* display: block;
            margin-left: auto;
            margin-right: auto;
            width: 50%; */
            height: 40px;
            width: 30px;
            padding-left: 3px;
        }
        .liveTagR {
            position: absolute;
            top: 5%;
            right: 12%;
            color: white;
            width: 1.1rem;
            height: 1.1rem;
            text-align: center;
            font-size: 0.7rem;
            font-weight: bold;
            margin-bottom: 1rem;
            border-radius: 50%;
            color:black;
            background-color: var(--cool-blue);
          display: table-cell;
          vertical-align: middle;
        }
        
        .liveTagL {
            position: absolute;
            top: 5%;
            right: 5%;
            color: white;
            width: 1.1rem;
            height: 1.1rem;
            text-align: center;
            font-size: 0.7rem;
            font-weight: bold;
            margin-bottom: 1rem;
            border-radius: 50%;
            color:black;
            background-color: var(--cool-blue);
          display: table-cell;
          vertical-align: middle;
          /* justify-content: center;
          align-items: center; */
        }

        .mosaicButtonsMainDiv {
            display: none;
            position: absolute;
            bottom: 2px;
            // right: 50px;
            // background: red;
            // background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgb(0, 0, 0) 100%);
            // background: #8d898970;
            background-color: rgba(0, 0, 0, 0.6);
            padding: 10px;
        }
        .MosaicButtonDiv {
            width: 35px;
            height: 35px;
            object-fit: contain;
            border-radius: 25px;
            box-shadow: $box-shadow_2;
            background-color: #0b70d1;
            @include flexCenter;
            margin-left: 5px;
            img {
                // margin-right: 2px;
                height: 22px;
                margin-left: 2px;
                margin-top: 2px;
            }
        }
        .MosaicButtonDivoff {
            width: 35px;
            height: 35px;
            object-fit: contain;
            border-radius: 25px;
            // box-shadow: $box-shadow_2;
            box-shadow: 0 1px 4px 3px rgb(247 247 247 / 49%);
            background-color: #002140;
            @include flexCenter;
            margin-left: 5px;
            img {
                // margin-right: 2px;
                height: 22px;
                margin-left: 2px;
                margin-top: 2px;
            }
        }

        .overlayText {
            position: absolute;
            top: 1%;
            right: 1%;
            z-index: 1;
          }
          
          #topText {
            color: white;
            // font-size: 20px;
            align-self: center;
            animation: flicker 4s infinite alternate;
            border: 1px solid white;
            padding: 0.5rem;
            font-weight: 700;
            // background-color: red;
          }

          @keyframes flicker {
            0%, 18%, 22%, 25%, 53%, 57%, 100% {
          
                text-shadow:
                0 0 4px #fff,
                0 0 11px #fff,
                0 0 19px #fff,
                0 0 40px #0fa,
                0 0 80px #0fa,
                0 0 90px #0fa,
                0 0 100px #0fa,
                0 0 150px #0fa;
            
            }
            
            20%, 24%, 55% {        
                text-shadow: none;
            }    
          }

          #topTextRed{
            color: red;
            // font-size: 20px;
            align-self: center;
            animation: flicker 4s infinite alternate;
            border: 1px solid red;
            padding: 0.5rem;
            font-weight: 700;

          }
          .mosaicSavedFilter {
            .SavedFilterDiv {
                margin: 0px !important;
                    .dropdown{
                        background: transparent;
                        border: unset;
                    }
                    .dropdown-toggle{
                        border: 0;
                        color: $color_4;
                    }

                    .btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show>.btn-light.dropdown-toggle {
                        color: $color_4;
                        background-color: transparent;
                    }
                    .dropdown-menu.show{
                        background: #626363;
                    }
                    .dropdown-item {                        
                        color: #ffffff !important;
                        &:hover{
                            color: #f1f1f1 !important;
                        }    
                        .SavedFilterOption {                           
                            &:hover{
                                background-color: #3b3b3b;
                                span{
                                    font-weight: 500;
                                    font-size: 15px;                                    
                                }
                            } 
                        }
                                          
                    }
              }

             
        
          }
}