$color_1: #8a8a8a;
$color_2: #4e4e4e;
$color_3: #4a595f;
$color_4: #272727;
$color_5: #000000;
$background-color_1: #ffffff;
$background-color_2: #eff5f8;
$background-color_3: #f5f5f5;
$background-color_4: #f2f6fe;

.lightTheme{
    .ReportAccordianTitleIcon {
        @include floatLeft;
    }
    .ReportAccordionMainContainer {
        background-color: $background-color_1;
        @include CardBoxBGShadow;
    }
    .ReportAccordianArrow {
        @include floatRight;
    }
    .ReportRightOptions {
        @include floatRight;
        img {
            @include floatLeft;
            margin: 0px 5px 0px 0px;
        }
        p {
            @include floatLeft;
            font-size: 12px;
            color: $color_2;
            padding: 0px;
            margin: 0px;
        }
    }
    .ReportSubTitleText {
        @include floatLeft;
        font-size: 12px;
        color: $color_2;
        padding-left: 15px;
        margin: 0px;
    }
    // .ReportTableHeader {
    //     background-color: $background-color_2;
    // }
    .ReportTableAlertSeverity {
        font-weight: bold;
    }
    .ReportTableFontBold {
        font-weight: bold;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .ReportTableHeadReport{
        .SearchViewInput{
            background-color: transparent;
        }
    }
    .ReportAccordionHeader {
        margin: 0.6rem 0;
        padding: 0.937rem 1.957rem 0.813rem 0.983rem;
        width: 100%;
        border: none;
        &:focus {
            outline: none;
        }
    }
    .report-title-head {
        display: inline-flex;
        width: 100%;
    }
    
    .report-table {
        margin-bottom: 0px !important;
        th {
            border-bottom: none !important;
            border-top: none !important;
        }
        tbody {
            >tr {
                &:last-child {
                    >td {
                        border-bottom: 0 !important;
                    }
                }
            }
        }
    }
    .ReportAccordianTitleText {
        @include floatLeft;
        h5 {
            color: $color_4;
        }
    }
    .profile-name {
        // height: 1.125rem;
        font-size: 0.813rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: $color_4;
    }
    .inputSearchLabelContainer {
        @include relativePosition;
        border: 1px solid #ccc;
        background-color: $background-color_1;
        width: 350px;
    }
    .ResizeTableColumn{
        resize: both;
        overflow: auto;
        width: 120px;
        height: 120px;
        margin: 0px;
        padding: 0px;
        border: 1px solid $color_5;
        display:block;
    }
    .CalenderDiv {
        width: 44%;
        display: inline-block;
        margin-top: 7px;
    }
    .SearchViewInput.cursor-ptr {
        margin-left: 1rem;
        width: 90%;
    }
    .EventReportTable{
        tbody {
            height: calc(100vh - 372px);
            @include devices(laptop_xs) {
                height: calc(100vh - 375px);
            }
            td {
                cursor: pointer;
                font-size: 14px;
                font-family: Segoe UI;
                font-weight: normal;
                line-height: 1.36;
                letter-spacing: normal;
                text-align: left;
                // padding-left: 16px !important;
                padding: 1rem;
                min-width: 250px;
                width: 100%;
                border-top: none !important;
                // @include devices(laptop_lg) {
                //     min-width: 272px;
                // }
            } 
        }    

    }
    .table-wrapper {
        width: 99%;
        overflow: auto;
        margin: 8px;
        table {
            // width: 115vw;
        }
        thead {
            background-color: #dbe6fc;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            border-top: 0px;
            // width: 100%;
            font-size: 0.8rem;
            display: table;
            table-layout: fixed;
            th {
                min-width: 300px;
                border: 0px;
            }
        }
        tbody {
            height: 545px;
            overflow: auto;
            display: block;
            table-layout: fixed;
            // border: 1px solid #d4d4d4;
            width: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            @include devices(laptop_xl) {
                height: 530px;
            }
            @include devices(laptop_lg) {
                height: 380px;
            }
            @include devices(laptop_md) {
                height: 380px;
            }
            @include devices(laptop_sm) {
                height: 250px;
            }
            tr:nth-child(even) {
                background-color: $background-color_3;
            }
        }
        tbody td {
            cursor: pointer;
            font-size: 14px;
            font-family: Segoe UI;
            font-weight: normal;
            line-height: 1.36;
            letter-spacing: normal;
            text-align: left;
            // padding-left: 16px !important;
            padding: 1rem;
            min-width: 300px;
            width: 100%;
            border-top: none !important;
            word-break: break-all;
            // @include devices(laptop_lg) {
            //     min-width: 272px;
            // }
        } 
        tbody tr:hover {
            background-color: $background-color_4;
        }
    }
    .ReportTableHeadReport th {
        .tableHeaderTooltip {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 270px; 
        }
    }

    .TabulerReportMainDiv {
        margin: 20px 10px 15px 10px;
        @include floatLeft;
        @include CardBoxBGShadow;
        width: 99%;
        table{
            height: 645px;
        }
    }
    .DeviceCurrentStatusDiv .EventReportTable td {
        font-size: 14px;
        font-family: Segoe UI;
        font-weight: normal;
        line-height: 1.36;
        letter-spacing: normal;
        text-align: left;
        padding-left: 20px !important;
        cursor: default !important;
    }
    .HeaderSpanHR{
        margin: 15px;
        font-size: 15px;
        font-weight: 500;
        color: $color_5;
    }    
    .HMHeader .btn-outline-primary {
        height: 32px !important;
        line-height: 1;
    }
    .inputTypeSearch {
        border: 0px !important;
        background: none !important;
        font-size: 13px;
        /* line-height: 29px; */
        color: #202124;
        /* margin: 5px 40px 5px 5px !important; */
        width: 90%;
        height: 35px;
        padding-left: 7px;
        &::placeholder {
            color: #c5c5c5;
            font-size: 13px;
            line-height: 18px;
        }
    }
}