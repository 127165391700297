$color_1: #bababa;
$color_2: #8a8a8a;
$color_3: #027aaa;
$background-color_1: #0b70d1;
$background-color_2: #F6F7F7;
$background-color_3: #1F1A24;
$background-color_4: #484848;
$background-color_5: transparent;

.darkTheme{
    .MainUserDetailsView {
        // width: 22%;
        // display: inline-block;
        width: 21%;
        float: left;
        background-color: #ffffff;
        border-radius: 21px;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
        @include devices(laptop_md) {
            width: 20%;
        }
        @include devices(laptop_lg) {
            width: 20%;
        }
        @include devices(laptop_xl) {
            width: 20%;
        }
    }
    .MainUserActivity {
        // width: 78%;
        // display: inline-block;
        width: 78%;
        margin-left: 15px;
        position: relative;
        float: left;
        background-color: #ffffff;
        border-radius: 13px;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
        @include devices(laptop_sm) {
            width: 77%;
        }
    }
    .UserDetailsView {
        height: 565px;
        background-color: $background-color_3;
        border-radius: 19px;
        // margin-right: 10px;
        height: calc(100vh - 128px);
    }
    .AdminBG {
        // height: 116px;
        height: 166px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        // background-image: linear-gradient(249deg, #72dcd6 8%, #1d88b3);
        background: transparent linear-gradient(180deg, #42F4F4 0%, #21437A 100%) 0% 0% no-repeat padding-box;
    }
    .RoundShapeDiv {
        text-align: center;
        margin-top: -68px;
    }
    .ProfilePicture {
        // height: 133px;
        // width: 133px;
        background-color: $background-color_2;
        border-radius: 50%;
        display: inline-flex;
        justify-content: flex-end;
        align-items: center;
        border: 2px solid #fff;
    }
    .ProfileName {
        font-size: 1rem;
        @include fontStyle;
        font-weight: bold;
        text-align: center;
        color: $color_1;
        margin-top: 15px;
        margin-bottom: 10px;
    }
    .ProfileSubName {
        margin-top: 15px;
        margin-bottom: 10px;
        font-size: 1rem;
        @include fontStyle;
        text-align: center;
        color: $color_1;
    }
    .AboutText {
        font-size: 0.875rem;
        @include fontStyle;
        font-weight: bold;
        color: $color_1;
        margin-bottom: 0px;
    }
    .DataBorder {
        height: 65px;
        border-bottom: solid 1px #efefef;
        display: flex;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
    }
    .changePassword {
        display: grid;
        place-items: center;
        justify-content: center;
        align-items: center;
    }
    .AboutTextDetails {
        font-size: 0.875rem;
        @include fontStyle;
        color: $color_1;
        margin-bottom: 0px;
        display: inline-block;
        margin-left: 15px;
    }
    .DetailsAboutHead {
        font-size: 0.875rem;
        @include fontStyle;
        font-weight: bold;
        color: $color_1;
        background-color: $background-color_5;
    }
    .imageWidth {
        display: inline-block;
        width: 20px;
        text-align: center;
    }
    .ActivityView {
        height: 565px;
        background-color: $background-color_3;
        border-radius: 11px;
        // margin-left: 10px;
        padding: 15px;
        height: calc(100vh - 128px);
    }
    .button-common-margin {
        margin-left: 0px !important;
    }
    .SettingsButton {
        width: auto;
        height: 28px;
        margin: 0 0.219rem 0 0.219rem;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 14px;
        background-color: $background-color_1;
        border: none;
        line-height: 0px;
    }
    .allcamerastxt {
      @include flexCenter;
    }
    .AlertUserTabs {
        p {
            width: 150px;
            display: inline-block;
            margin-bottom: 0px;
            font-size: 0.875rem;
            @include fontStyle;
            text-align: center;
            color: $color_2;
        }
    }
    .SelectedAlertTabs {
        font-weight: bold !important;
        color: $color_3 !important;
        border-bottom: 3px solid #027aaa;
        padding-bottom: 10px;
    }
    .MainUserList {
        margin-top: 10px;
        height: 450px;
        overflow: auto;
        scroll-behavior: smooth;
        margin-bottom: 10px;
        height: calc(100vh - 318px);
    }
    .UserViewDiv {
        width: 32%;
        height: 3.125rem;
        margin: 5px;
        padding: 0.625rem 0.682rem 0.625rem 0.625rem;
        background-color: $background-color_5;
        border: 1px solid #464646;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        cursor: default;
        border-radius: 7px;
    }
    .UserNumberText {
        margin-bottom: 0px;
        margin-left: 1rem;
        font-size: 0.75rem;
        @include fontStyle;
        color: $color_1;
        display: inline-block;
    }
    .ChangeButton {
        text-align: center;
        height: 65px;
        padding-top: 16px;
        border-bottom: solid 1px #efefef;
    }
    .soundToggleDiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 45px;
        padding: 0px 12px;
    }
    .changePasswordName {
        font: normal normal normal 15px / 32px Segoe UI;
        text-align: left;
        float: left;
    }
    .profileHorizontalLine {
        float: left;
        width: calc(100% + 100px);
        margin: 50px -50px;
    }
    .disableEyeIcon {
        cursor: not-allowed;
    }
}
