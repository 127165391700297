

/* 04. Media Query 1366px to 1439px Starts  */
@media screen and (min-width:1366px) and (max-width: 1439px) {

    .btn.TextRoot.contactNumUser {
         padding: 0.375rem 0.2rem
    }
    .workflowPreview {
        width: 760px !important;
        height: 495px !important;
    }

   /*  .GridMain {
        width: 245px;
        margin: 0 10px 10px 0px;
    } */
    
    .imageWhiteBg {
        /* height: 60px; */
    }

    .BigImage img {
        width: 480px;
    }

    .WhiteFormSignIn {
        max-width: 350px;
    }

    .loginPage-img {
        height: 22rem;
    }

    /* .chatFlowWebMaiContainer {
        height: calc(100vh - 120px);
    } */
    .alert-report-section {
        height: 460px;
    }

    .chatflow-page-container-div {
        height: calc(100vh - 233px);
        overflow: overlay;
    }

    .mosaic-options {
        width: 24.438rem;
    }

    /* Config tool start */
    .lst-header-width {
        width: 445px;
    }

    .RightListSide {
        height: 640px;
    }

    .SiteListMain {
        height: 510px;
    }

    /* .review-section {
        height: 451px;
    } */

    /* .review-table-body {
        height: 344px !important;
    } */

    .AddDevices1 {
        height: 626px;
    }

    .camdev-bulkupload {
        height: 325px;
    }

    .lst-section-width {
        height: 325px;
        overflow: auto;
    }

    .userTemplate-FRAssignment-container {
        height: 588px;
    }

    /* .site-form {
        height: 484px;
    }
 */
    .panel-singledev {
        height: 392px;
    }

    .alert-template-form {
        height: 350px;
    }

    .infinite-scroll-div {
        /* padding-left: 20px; */
        height: calc(100vh - 185px);
    }

    /* .OperateByDiv {
        margin-left: 10px;
    } */

    .ChartsButtonDiv {
        width: 42%;
        text-align: end;
    }

    .BulkText.userTemplate-next-btn-div {
        margin-top: 30px !important;
    }

    /* For Dashboard */
  
    .dashboardScroll{
        height: 525px;
        overflow-y: auto;
        overflow-x: hidden;
        padding-top: 10px;
    }
    .grid {
        grid-template-columns: repeat(4, 1fr) !important;
    }
    .grid-template-rows-1 {
        padding-right: 7px;
    }
    .tbody-dashboard {
        height: calc(100% - 90px) !important;
    }
    .removeClickStyle {
        width: 90% !important;
        margin: 12px;
    }
    
    .infinite-scroll-div-dashboard {
        height: calc(100vh - 281px);
    }

    .dashboard_configuration_templateName {
        font-size: 1rem;
        width: 64% !important;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .qc-actions {
        float: right;
    }

    .qc-actions>img {
        padding: 0 0 0 0.5rem;
    }


    .kpi-card.quick-config-widget {
        height: 20rem;
    }
    .QuickConfigHeight {
        height: 215px !important;
    }
    .dashboard-card-content {
        margin: 1.2rem;
    }
    .ovalDiv{
        width: 30%;
        background-size: 100px 100px;
    }

    /* For Alerts Grid in Alerts Page*/
    .view-workflow-btn {
        padding-left: 4rem !important;
    }

    /* Camera page */
    .cameraCTA-Container {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;
    }

    .common-pagination {
        margin-right: 600px !important;
    }

    .PageOfLine.SearchTextDiv {
        width: 15%;
        margin: 0;
        height: 35px;
    }

    .MainCameraView {
        height: calc(100vh - 243px);
    }

    .SearchTextDiv {
        /* margin-left: 15px; */
        width: 144px;
    }
    
    /* Alert Page */
    /* .AlertActivityReportTable {
        height: calc(100vh - 180px);
    } */

    .table-fixed {
        margin: 12px;
    }
   /*  .table-fixed tbody {
        height: calc(100vh - 305px);
    } */

    .no-report-found {
        text-align: center;
        transform: translate(0%, 0%);
        font-size: 1.5rem;
    }

    /* Config tools */
    .siteConfig {
        height: 250px !important;
    }

  /*   .BulkText {
        margin-top: 16px;
    } */

    .addUpdateCamera-btn {
        margin-top: 8px !important;
    }

    .add-devices-btn-div .NextMoveButton {
        /* margin-top: -9px; */
        margin-top: 0.5rem;
    }

    /* For Bulk Panel Uploads */
    .lst-section-width {
        height: 343px;
        overflow-x: hidden;
    }

    div.column select.SiteNameTextBox {
        width: 90%;
        margin-left: -9px;
    }

    /* Alert Template - Add Site line 817 */
    .NextMoveButton {
        /* margin-top: -60px; */
        /* margin-top: 0.2rem; */
    }

    .userTemplate-addUser-conatiner {
        padding-top: 0 !important;
    }

    .EnableNextStep {
        margin-top: -10px;
    }

    .UserDetailsView,
    .ActivityView {
        height: calc(100vh - 120px);
    }

    .MainUserList {
        margin-bottom: 10px;
        height: calc(100vh - 250px);
    }

    .RightListSide {
        height: calc(100vh - 179px);
    }

    .AddUser {
        height: calc(100vh - 180px);
    }

    .wf-list {
        height: calc(100vh - 312px);
    }

    .SiteListMain {
        height: calc(100vh - 241px);
    }

    .review-section {
        height: calc(100vh - 215px);
    }

    .review-table-body {
        height: calc(100vh - 330px) !important;
    }

    .ManageSite.userTemplate-FRAssignment-container {
        height: 466px;
        margin-bottom: 14px;
        /* background: #FFFF71; */
    }

    .ManageSite.ManageSiteGroup-container {
        height: 470px;
    }

    .sitegrp-sitelist {
        height: 300px;
    }

    .site-group-list {
        height: 300px;
    }
   
    /* .BulkText {
        margin-top: 20px;
    } */

    .ManageSite.addUser-container {
       /*  height: calc(100vh - 180px); */
        margin-bottom: 14px;
    }

    .LeftAddSide .AddDevices1 {
        height: 470px;
    }

    .DeviceDivScroll {
        height: 307px;
    }

    .cam-device-form {
        height: 291px;
    }

    .lst-section-width {
        height: 309px;
        overflow-x: hidden;
    }

    .add-devices-btn-div.bulk-upload-main-row-length.row .NextMoveButton {
        /* position: absolute; */
        position: static;
        right: 0rem;
        top: -15px;
    }

    .lst-header-width {
        /* width: 370px; */
        width: 100%;
    }
    
    .column {
        float: left;
        width: 40%;
        padding: 10px;
    }

    .column1 {
        float: left;
        width: 60%;
        padding: 10px;
    }

    .BulkUploadInput {
        border: none;
        width: 81%;
    }

    #hook-form .DeviceTextBox .SiteNameTextBox {
        width: 100%;
        margin-left: 0rem;
    }

    .reports-dateRange-and-searchBox .SearchViewInput.cursor-ptr {
        /* width: 55%;
        margin-left: 140px; */
    }

    .alert-template-fullDay-textbox {
        width: 105.5%;
    }

    .px-0.reactDatePicker-container.col-lg-4 {
        padding-left: 0.5rem !important;
    }

    .pr-4.pl-0.alertTemplate-reactDatePicker-box2.col-lg-6 {
        margin-left: 9.2rem !important;
    }

    .alert-template-duration-container .reactDatePicker-container .react-datepicker-wrapper {
        width: 12.6rem;
    }

    .dropArrow-icon {
        left: 172px !important;
    }

    .react-datepicker-popper[data-placement^=bottom] {
        padding-top: 2px;
    }

    .NextMoveButton.addWorkFlow-saveWorkFlow-btn {
        margin-top: 3px !important;
    }

    .OperationAccordionBgDiv .workflow-action-input-box {
        min-height: 6rem;
    }

    .AddMore.workflowPage .NextMoveButton {
        margin-top: 0 !important;
    }
/* For data access screen style start */
    .StatusSearchText{
        margin-left: 0px;
    }
    .StatusSearchText .SearchViewInput {
        width: 87% !important;
    }
    .dataAccessSearchInput {
        width: 84%;
    }
    .DataTableView {
        height: 42vh;
    }

    .react-flow {
        /* position: sticky !important;
        height: 92% !important; */
    }

    .dataaccessreactflow .react-flow {
        height: 92% !important;
    }

    .multiplaybackplayer {
        height: 175px !important;
    }

    /* .BoxShadowDiv {
        height: 410px !important;
    } */
    /* .react-flow{
        height: 88% !important;
    } */
    .selectionModel {
        min-width: 79%;
    }
    /* commented by Alam because the canvas was gooing out of main container */
    /* .ManageSite.data-access-container {
        height: 78vh;
    } */
    .SelectAllSearchBarDiv {
        /* background-color: #f2f6fe;
        border-radius: 7px 7px 0 0;
        padding: 10px; */
        height: 14vh;
    }
/* For data access screen style end */

/* for health monitoring page */
/* .devices-text {
    min-width: 150px;
}
.healthmonitoringTable tbody {
    height: calc(100vh - 585px);
}
.viewTimelineMsg {
    height: 190px;
} */


    /* For view alert page hgt */
    .hgtWorkFlowDiv{
        height: 478px;
        overflow: auto;
    }
  /*   .hgtWorkFlowDiv .GridMain {
        width: 211px !important;
        margin: 0 10px 10px 0px;
    } */

    .SampleTextDocument {
        margin-left: 0px;
    }

   /*  .SiteLeftContainer .SearchViewInput1 {
        width: 90% !important;
    } */
  /*   .siteformContainer {
        height: 378px !important;
    } */
   /*  .StepperSiteList {
        height: 350px !important;
    } */

   /*  .customerDropdown {
        width: 76% !important;
        padding-left: 12px;
    } */

    .bulk-upload-msg-text {
        margin-right: 50px;
    }

 /*    .ListofText {
        width: 275px;
    } */

    .activityLogTab {
        padding: 4px 4px !important;
    }

    /* .ChartsButtonDiv {
        width: 35px;
    } */
}

/* Media Query 1366px to 1439px Ends  */

/* 05. Media Query 1440px to 1535px Starts  */
@media screen and (min-width:1440px) and (max-width: 1535px) {
   
 /*    .GridMain {
        width: 245px;
        margin: 0 10px 10px 0px;
    } */
    .SiteListMain {
        height: calc(100vh - 240px);
    }
   
    .dashboardScroll{
        height: 605px;
        overflow-y: auto;
        overflow-x: hidden;
        padding-top: 10px;
    }
    .grid {
        grid-template-columns: repeat(4, 1fr) !important;
    }
    .grid-template-rows-1 {
        padding-right: 7px;
    }
    /* For Login page */
    .imageWhiteBg {
        /* height: 120px; */
    }
    .BigImage img {
        width: 550px;
    }

    .loginPage-img {
        height: 25rem;
    }

    .WhiteFormSignIn {
        margin-left: 0px;
        max-width: 450px;
    }

    /* For Profile Page */
    .UserDetailsView,
    .ActivityView {
        height: calc(100vh - 180px);
    }

    .MainUserList {
        margin-bottom: 10px;
        height: calc(100vh - 318px);
    }

    /* For Dashboard */
    

    .infinite-scroll-div-dashboard {
        height: calc(100vh - 418px);
    }

    .dashboard_configuration_templateName {
        font-size: 1rem;
        width: 71% !important;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .qc-actions {
        float: right;
    }

    .qc-actions>img {
        padding: 0 0 0 0.5rem;
    }

    .infinite-scroll-div {
        height: calc(100vh - 200px);
    }

    /* Chatflow */
    .view-workflow-btn {
        padding-left: 4rem !important;
    }

    /* .chatFlowWebMaiContainer {
        height: calc(100vh - 120px);
    } */
    .alert-report-section {
        /* height: 695px; */
        height: 715px;
    }

    .chatflow-page-container-div {
        height: calc(100vh - 233px);
    }

    /* Cameras page */
    .cameraCTA-Container {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;
    }

    .common-pagination {
        margin-right: 43rem !important;
    }

    .MainCameraView {
        height: calc(100vh - 245px);
    }

    .SearchTextDiv {
        /* margin-left: 29px; */
        /* width: 144px; */
    }


    /* Reports Page */
    /* .AlertActivityReportTable {
        height: calc(100vh - 180px);
    } */

    /* .table-fixed tbody {
        height: calc(100vh - 345px);
    } */

    .no-report-found {
        height: calc(102vh - 287px);
    }

    .BulkText.userTemplate-next-btn-div {
        margin-top: 30px !important;
    }

    .ManageSite.addUser-container {
        height: 709px;
        margin-bottom: 14px;
    }

    .ManageSite.addUser-container .site-group .AddMore {
        margin-top: -10px;
    }

    .configureUserForm-container {
        height: 550px;
        overflow: auto;
    }

    .addUpdateCamera-btn {
        margin-top: 7px;
    }

    .add-devices-heading {
        width: 47%;
    }

   /*  .lst-section-width {
        overflow-x: hidden;
        height: 310px;
    } */

    div.column select.SiteNameTextBox {
        width: 90%;
        margin-left: -10px;
    }

    #hook-form .DeviceTextBox .SiteNameTextBox {
        margin-left: -1.9rem;
    }

    .site-list-container {
        height: 445px;
    }

    .sitegrp-sitelist {
        height: 400px;
    }

    .ManageSiteGroup-container {
        height: 720px;
    }

    .ManageSiteGroup-NextButton {
        margin-top: 20px !important;
    }

    .ManageSiteGroup-AddSelectedButton {
        margin-top: 20px !important;
    }

    /* User Template */
    .userTemplate-addUser-conatiner {
        height: 319px;
        padding-top: 0 !important;
    }

    .EnableNextStep {
        margin-top: -10px;
    }

    .ManageSiteDiv .text-right .NextMoveButton {
        margin-top: 20px !important;
    }

    /* Review */
    .review-section {
        height: calc(100vh - 210px);
    }

    .review-table-body {
        height: calc(100vh - 335px) !important;
    }

    .LeftAddSide .AddDevices1 {
        height: 620px;
    }

    .DeviceDivScroll {
        height: 450px;
    }
    .cam-device-form{
        height: 400px;
    }

    .ManageSite.userTemplate-FRAssignment-container {
        height: 620px;
    }

    .site-group-list {
        height: 440px;
    }
    .LeftAddSideHeight{
        height: calc(100vh - 142px);
    }

    /* .AddSite.alertTemplate-addUpdate-container {
        height: 384px;
    } */

    .reports-dateRange-and-searchBox .SearchViewInput.cursor-ptr {
        /* width: 50%;
        margin-left: 170px; */
    }

    .alert-template-fullDay-textbox {
        width: 104%;
    }

    .px-0.reactDatePicker-container.col-lg-4 {
        padding-left: 0.4rem !important;
    }

    .alert-template-duration-container .reactDatePicker-container .react-datepicker-wrapper {
        width: 13.5rem;
    }

    .pr-4.pl-0.alertTemplate-reactDatePicker-box2.col-lg-6 {
        margin-left: 9.65rem !important;
    }

    .dropArrow-icon {
        left: 185px !important;
    }

     /* New cam page css start tk */
   /*   .rightcameradiv {
        width: 47%;
    } */


    .TransperentDiv .image-bg {
        height: 150px;
    }
    .LocationNameText span {
        font-size: 0.8rem;
        font-weight: 400;
    }
    .CameraNameText1 {
        font-size: 1rem;
    }
    .MainCameraContainer1 {
        height: calc(100vh - 152px);
    }

    /* New cam page css end tk */

    .NextMoveButton.addWorkFlow-saveWorkFlow-btn {
        margin-top: 3px !important;
    }

    /* for health monitoring page */
    /* .devices-text {
        min-width: 150px;
    }
    .healthmonitoringTable tbody {
        height: calc(100vh - 585px);
    }
    .viewTimelineMsg {
        height: 190px;
    } */

    /* For data access screen style start */
    .StatusSearchText{
        width: 144px;
        margin-left: 0px;
    }
    .StatusSearchText .SearchViewInput {
        width: 87%;
    }
    .dataAccessSearchInput {
        width: 87%;
    }
    .DataTableView {
        height: 57vh;
    }
   /*  .BoxShadowDiv {
        height: 78vh;
    } */
    .selectionModel {
        min-width: 79%;
    }
/* For data access screen style end */

    /* For view alert page hgt */
    .hgtWorkFlowDiv{
        height: 570px;
        overflow: auto;
    }

    /* .SiteLeftContainer .SearchViewInput1 {
        width: 92%;
    }
    .siteformContainer {
        height: 405px;
    }
    .StepperSiteList {
        height: 375px;
    } */
   /*  .customerDropdown {
        width: 78%;
    } */

    .data-access-nonedit .react-flow {
        height: 94% !important;
    }

    /* .ChartsButtonDiv {
        width: 33px;
    } */
}

/* Media Query 1440px to 1535px Ends  */

/* 06. Media Query 1536px to 1679px Starts  */
@media screen and (min-width:1536px) and (max-width: 1679px) {

    /* For Login page */
    .imageWhiteBg {
        /* height: 100px; */
    }

    .BigImage img {
        width: 550px;
    }

    .loginPage-img {
        height: 25rem;
    }

    .WhiteFormSignIn {
        margin-left: 0px;
        max-width: 450px;
    }

    /* For Dashboard */
    /* .dashboardScroll{
        height: 640px;
        overflow-y: auto;
        overflow-x: hidden;
        padding-top: 10px;
    } */

    .grid {
        grid-template-columns: repeat(4, 1fr) !important;
    }

    .grid-template-rows-1 {
        padding-right: 7px;
    }
    /* Rupesh's Revised Code 29/11/2022 */
   
    .infinite-scroll-div-dashboard {
        height: calc(100vh - 382px);
    }

    /* For Alerts Grid in Alerts Page*/
  

    .infinite-scroll-div {
        height: calc(100vh - 200px);
    }

    /* Chatflow */
    .view-workflow-btn {
        padding-left: 5.8125rem !important;
    }

    /* .chatFlowWebMaiContainer {
        height: calc(100vh - 106px);
    } */
    .alert-report-section {
        height: 588px !important;
    }

    .chatflow-page-container-div {
        height: calc(100vh - 233px);
    }

    /* Cameras Page */
    .cameraCTA-Container {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;
    }

    .common-pagination {
        margin-right: 750px !important;
    }

    .PageOfLine.SearchTextDiv {
        width: 15%;
        margin: 0;
        height: 35px;
    }

    .SearchTextDiv {
        /* margin-left: 43px;
        width: 144px; */
        /* width: 20rem; */
    }

    .alertPageSearchTextDiv{
        width: auto !important;
    }

    .MainCameraView {
        height: calc(100vh - 243px);
    }

    /*Reports Page */
    /* .AlertActivityReportTable {
        height: calc(100vh - 180px);
    } */

    /* .AlertActivityReportTable .table-fixed tbody {
        height: calc(100vh - 310px);
    } */

    .no-report-found {
        height: calc(102vh - 286px);
    }

    .addUpdateCamera-btn {
        margin-top: 7px;
    }

    /* For Bulk Panel Uploads */
    .BulkUploadInput {
        width: 83%  ;
    }
    .lst-section-width {
        overflow-x: hidden;
        height: 221px;
    }
   /*  .customerDropdown {
        width: 79%;
    } */

    .DeviceDivScroll{
        max-height: 430px;
    }
    .cam-device-form{
        height: 390px;
    }
    div.column select.SiteNameTextBox {
        width: 80%;
        margin-left: 22px;
    }

    #hook-form .DeviceTextBox .SiteNameTextBox {
        width: 100%;
        margin-left: -0.9rem;
    }

    .ManageSite.userTemplate-FRAssignment-container {
        height: 580px;
        /* background: #FFFF71; */
    }
    .LeftAddSideHeight {
        height: calc(100vh - 139px);
    }
    .wf-list {
        height: calc(100vh - 285px);
    }
    .AddUser {
        height: calc(100vh - 164px);
    }
    .sitegrp-sitelist {
        height: 420px;
    }

    .site-group-list {
        height: 421px;
    }

    .AddSite {
        /* height: 494px; */
    }


    .ManageSite.addUser-container {
        height: calc(100vh - 167px);
        margin-bottom: 14px;
    }

    .ManageSite.addUser-container .site-group .AddMore {
        margin-top: -10px;
    }

    .ManageSite.addUser-container .site-group .site-group-list {
        height: 534px;
    }

    .configureUserForm-container {
        height: 490px;
        overflow: auto;

    }

    .ManageSite.ManageSiteGroup-container {
        height: 582px;
    }

    /* Review */
    .review-section {
        height: calc(100vh - 210px);
    }

   /*  .table-fixed tbody {
        height: calc(100vh - 350px);
    } */
    .review-table-body {
        height: calc(100vh - 335px) !important;
    }

    .reports-dateRange-and-searchBox .SearchViewInput.cursor-ptr {
        /* width: 50%; */
        /* margin-left: 190px; */
    }

    .alert-template-fullDay-textbox {
        width: 103%;
    }

    .px-0.reactDatePicker-container.col-lg-4 {
        padding-left: 0.35rem !important;
    }

    .alert-template-duration-container .reactDatePicker-container .react-datepicker-wrapper {
        width: 6.9rem;
    }

    .pr-4.pl-0.alertTemplate-reactDatePicker-box2.col-lg-6 {
        margin-left: 0.75rem !important;
    }

    .dropArrow-icon {
        left: 86px !important;
    }

    /* For Mosaic */
    .NextMoveButton.addWorkFlow-saveWorkFlow-btn {
        margin-top: 3px !important;
    }

    /* for health monitoring page */
    /* .devices-text {
        min-width: 150px;
    }
    .healthmonitoringTable tbody {
        height: calc(100vh - 689px);
    } */

    /* For view alert page hgt */
    .hgtWorkFlowDiv{
        height: 605px !important;
        overflow: auto;
    }

   /*  .SiteLeftContainer .SearchViewInput1 {
        width: 92%;
    } */
   /*  .siteformContainer {
        height: 518px !important;
    }
    .StepperSiteList {
        height: 490px !important;
    } */
    /* .dashboard_configuration_templateName {
        /* font-size: 1rem; 
        width: 72%;
        /* text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden; 
    } */
/*     .dataAccessSearchInput {
        width: 85%;
    } */
    .UserDataAccessWorkflow {
        height: 570px !important;
    }
    /* .BoxShadowDiv {
        height: 70vh !important;
    } */
    .dataaccessreactflow .react-flow {
        height: 94% !important;
    }
    .SelectAllSearchBarDiv {
        height: 12vh;
    }
    .DataTableView {
        height: 37vh;
    }
    .SiteListMain {
        height: calc(100vh - 225px);
    }

    /* .ChartsButtonDiv {
        width: 30px;
    } */
}

/* Media Query 1536px to 1679px Ends  */

/* 07. Media Query 1680px to 1919px Starts  */
@media screen and (min-width:1680px) and (max-width: 1919px) {

    .dashboardScroll{
        height: 720px;
        overflow-y: auto;
        overflow-x: hidden;
        padding-top: 10px;
    }
    .grid {
        grid-template-columns: repeat(4, 1fr) !important;
    }

    .grid-template-rows-1 {
        padding-right: 7px;
    }
    .imageWhiteBg {
        /* height: 197px;
        padding-top: 10px; */
    }

    .BigImage img {
        width: 550px;
    }

    .loginPage-img {
        height: 25rem;
    }

    .WhiteFormSignIn {
        width: 488px;
    }

    .SearchTextDiv {
        /* margin-left: 68px; */
        /* width: 143px; */
    }

    .VideomainDiv {
        width: 73%;
    }

    .VideomainButtonDiv {
        width: 25%;
    }

    /* .table-fixed tbody {
        height: calc(100vh - 350px);
    } */

    .infinite-scroll-div {
        height: calc(100vh - 200px);
    }

    /* .chatFlowWebMaiContainer {
        height: calc(100vh - 106px);
    } */
    .alert-report-section {
        height: 895px;
    }
    .col-2.view-workflow-btn {
        padding-left: 7rem !important;
    }

    .chatflow-page-container-div {
        height: calc(100vh - 233px);
    }

    .cameraCTA-Container {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;
    }

    .common-pagination {
        margin-right: 850px !important;
    }

    .PageOfLine.SearchTextDiv {
        width: 15%;
        margin: 0;
        height: 35px;
    }

    .MainCameraView {
        height: calc(100vh - 243px);
    }

    /* .AlertActivityReportTable {
        height: calc(100vh - 180px);
    } */

    /* .AlertActivityReportTable .table-fixed tbody {
        height: calc(100vh - 324px);
    } */

    .RightListSide {
        height: calc(100vh - 180px);
    }

    .SiteListMain {
        height: calc(100vh - 241px);
    }

/*     .site-form {
        height: 644px;
    } */

    .AddDevices1 {
        height: 590px;
    }
    .cam-device-form{
        height: 390px;
    }


    .camdev-bulkupload {
        height: 483px;
    }


    /* .ManageSite {
        height: 870px;
    } */

    .ManageSite.ManageSiteGroup-container {
        height: 590px;
    }

    .site-group-list{
        max-height: 400px;
    }
    .userTemplate-FRAssignment-container {
        height: 870px;
    }

    .ManageSite.userTemplate-FRAssignment-container {
        height: 590px;
    }

   /*  .site-group-list {
        height: 696px;
    } */

    .sitegrp-sitelist {
        height: 395px;
    }

    .lst-section-width{
        height: 310px;
    }
    .DeviceDivScroll{
        max-height: 430px;
    }
    .AddUser {
        height: calc(100vh - 180px);
    }

    .wf-list {
        height: calc(100vh - 312px);
    }

    /* .review-section {
        height: 710px;
    } */

    /* .review-table-body {
        height: 570px !important;
    } */


    .user-formdt {
        height: 129px;
    }

  /*   .addUser-container {
        height: 525px;
    }
 */
  /*   .ManageSite.addUser-container {
        height: calc(100vh - 191px) !important;
        margin-bottom: 14px;
    } */

    .ManageSite.addUser-container .site-group .AddMore {
        margin-top: -10px;
    }

  /*   .configureUserForm-container {
        height: 550px;
        overflow: auto;
    }
 */
    .addUpdateCamera-btn {
        margin-top: 10px;
    }


    #hook-form .DeviceTextBox .SiteNameTextBox {
        margin-left: 0.7rem;
    }

    .SiteNameTextBox {
        width: 80%;
    }

    .userTemplate-addUser-conatiner {
        height: 383px;
    }

    .BulkText.userTemplate-next-btn-div {
        margin-top: 0px !important;
    }

    .userTemplate-next-btn {
        margin-top: 0px;
        margin-bottom: 25px;
    }
    .infinite-scroll-div-dashboard {
        height: calc(100vh - 499px);
    }

    .UserDetailsView,
    .ActivityView {
        height: calc(100vh - 180px);
    }

    .MainUserList {
        margin-bottom: 10px;
        height: calc(100vh - 318px);
    }

    .review-section {
        height: calc(100vh - 210px);
    }

    .review-table-body {
        height: calc(100vh - 335px) !important;
    }

    .reports-dateRange-and-searchBox .SearchViewInput.cursor-ptr {
        /* width: 50%;
        margin-left: 210px; */
    }

    .alert-template-fullDay-textbox {
        width: 90%;
    }

    .px-0.reactDatePicker-container.col-lg-4 {
        padding-left: 0.2rem !important;
    }

    .alert-template-duration-container .reactDatePicker-container .react-datepicker-wrapper {
        width: 6.4rem;
    }

    .dropArrow-icon {
        left: -18px !important;
        top: 1px;
    }

    /* For Mosaic */
   .NextMoveButton.addWorkFlow-saveWorkFlow-btn {
        margin-top: 3px !important;
    }

    /* for health monitoring page */
    /* .devices-text {
        min-width: 240px;
    }
    .healthmonitoringTable tbody {
        height: calc(100vh - 689px);
    } */
    
    /* For view alert page hgt */
    .hgtWorkFlowDiv{
        height: 570px;
        overflow: auto;
    }

   /*  .SearchViewInput1 {
        width: 93% !important;
    }   */  

    .SearchNotification .SearchViewInput {
        width: 93% !important;
    }
    
    /* .siteformContainer {
        height: 664px !important;
    } */

    /* .StepperSiteList {
        height: 640px !important;
    } */
}

/* Media Query 1680px to 1919px Ends  */

/* 08. Media Query 1920 Starts  This is our default resolutions done in app.css*/
@media screen and (min-width:1920px) {

    /*#region DASHBOARD PAGE STARTS */
   /*  .kpi-card.quick-config-widget {
        height: 23.438rem;
        margin-bottom: 10px;
    }

    .tab-content .tab-pane.template-list {
        height: 266px;
    }
 */
    /*#endregion DASHBOARD PAGE ENDS*/

    /* CHECK LATER */
    .siteConfig {
        height: 350px !important;
       /*  height: 246px !important; */
    }

    .RightListSide {
        height: calc(100vh - 145px);
    }

    .LeftAddSideHeight{
        height: calc(100vh - 175px);
    }
    .SiteListMain {
        height: calc(100vh - 212px);

    }

    .WhiteFormSignIn {
        width: 480px;
    }
}

