$color_1: #ffffff;
$color_2: #182e37;
$color_3: #bababa;
$background-color_1: #f0f3f5;
$background-color_2: #f4f3f2;
$background-color_3: #1F1A24;
$background-color_4: transparent;


.darkTheme {
    .OverlayChat {
        position: fixed !important;
        bottom: 0px;
        display: flex;
        align-items: flex-end;
        /* justify-content: flex-end; */
        overflow-x: auto;
        scroll-behavior: smooth;
        width: auto !important;
        overflow-y: hidden;
        z-index: 99999;
    }
    .chatFlowMobileHeader {
        position: relative;
        height: 122px;
    }
    .chatFlowMobileHeaderTop {
        @include absolutePosition;
        border-radius: 22px 22px 0px 0px;
        background-color: $background-color_3;
        height: 60px;
        width: 100%;
        z-index: 1;
        cursor: pointer;
    }
    .HeaderFireAlert {
        display: flex;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        height: 55px;
        margin-left: 15px;
        margin-right: 15px;
    }
    .chatFlowMobileAlertTypeText {
        @include floatLeft;
        margin: 0px 15px 0px 0px !important;
        padding: 0px !important;
    }
    .FireAlertTextp {
        font-size: 1rem;
        @include fontStyle;
        font-weight: bold;
        color: $color_1;
    }
    .SeverityBGDiv {
        float: left;
        width: 4.875rem;
        height: 24px;
        border-radius: 4px;
        text-align: center;
    }
    .ExtremeValue {
        font-size: 0.75rem;
        font-weight: normal;
        color: $color_1;
    }
    .chatFlowClockDiv {
        background-color: $background-color_2;
        border-radius: 25px;
        width: 60px;
        float: left;
        margin: 2px 0px 0px 86px;
    }
    .chatFlowClockClass {
        float: left;
        margin: 1px 4px 1px 1px;
    }
    .chatFlowMobileAlertTime {
        color: #828c90;
        font-size: 11px;
        float: left;
        margin: 0px 0px 0px 0px !important;
        padding: 0px !important;
    }
    .LinkButtonDiv {
        display: inline-block;
    }
    .LinkButtonView {
        border: none;
        background: none;
        width: 25px;
        line-height: 0;
    }
    .chatFlowMobileHeaderBottom {
        position: absolute;
        top: 45px;
        border-radius: 0px 0px 12px 12px;
        // background-color: #027aaa;
        background-color: #0f62ac;
        height: 77px;
        width: 100%;
        // z-index: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .panel-collapse {
        // max-height: 500px;
        max-height: 550px;
        overflow: hidden;
        transition: max-height .25s ease;
    }
    .panel-close {
        max-height: 0;
    }
    .ChatbotTextButtonDiv {
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        margin-top: 5px;
        width: 75%;
        @include devices(laptop_sm) {
            width: 73%
        }
    }
    .chatFlowMobileBtmOne {
        margin: 25px 15px 0px 15px;
        float: left;
    }
    .chatFlowMobileAlertBtoomText {
        color: #ffffff;
        font-weight: bold;
        font-size: 13px;
        float: left;
        margin: 0px 15px 0px 0px !important;
        padding: 0px !important;
    }
    .hooter-twoway-div {
        display: flex;
        justify-content: end;
        margin: 17px 15px 0px 0px;
    }
    .chatFlowMobileBtmTwo {
        margin: 0px 15px 0px 15px;
        float: left;
    }
    .chatFlowMobileDetailText {
        color: #ffffff;
        font-size: 12px;
        float: left;
        margin: 0px 0px 0px 0px !important;
        padding: 0px 0px !important;
    }
    .ChatDetailsDiv {
        /* height: 300px; */
        overflow: auto;
        /* margin: 30px 20px; */
    }
    .multi-chatbot-container-div {
        /* height: 500px;
        overflow: overlay; */
        height: auto;
                .ChatScrollHgt{
                    height: calc(100vh - 550px);
                    overflow: hidden;
                    overflow-y: auto;
                    scroll-behavior: smooth;
                }
        @include devices(laptop_xl) {
            height: 475px;
        }
        @include devices(laptop_lg) {
            height: 360px;
        }
        @include devices(laptop_sm) {

        }
    }
    .react-functional-chatbot-inner-div {
        margin: 10px;
    }
    .chatFlowBoxContainer-bot {
        width: 100%;
        /* float:left; */
        margin: 5px 0px 0px 0px;
        display: inline-flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
    .chatFlowTimeIconContainerBig {
        background-color: #ffffff;
        border-radius: 25px;
        height: 25px;
        width: 25px;
        float: left;
    }
    .chatFlowChatTimeIconBig {
        float: left;
        padding: 0px !important;
        margin: 1px 0px 0px 1px;
        height: 22px;
    }
    .chatFlowChatTimeIconTextBig {
        color: #828c90;
        font-size: 12px;
        float: left;
        margin: 0 1rem !important;
        padding: 0px !important;
    }
    .chatFlowChatBoxBig {
        background-color: $background-color_3;
        border-radius: 0px 10px 10px 10px;
        @include floatLeft;
        margin: 0px 0px 0px 0px;
    }
    .chatFlowChatTextBig {
        color: $color_1;
        font-size: 14px;
        @include floatLeft;
        margin: 10px !important;
        padding: 0px !important;
    }
    .chatFlowWebChatBox1 {
        position: sticky;
        bottom: 0;
        float: left;
        height: auto;
        width: 100%;
        background-color: $background-color_3;
        border-radius: 10px;
        margin-top: 1rem;
        .chatFlowBoxContainer {
            height: 60px;
            @include devices(laptop_sm) {
                height: 40px;
            } 
        }
    }
    // chatflow reason code scss
    .chatFlowBoxContainer {
        width: 100%;
        /* float:left; */
        margin: 5px 0px 0px 0px;
        display: inline-flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
    }
    .chatFlowWebChatBoxInput {
        border: none;
        font-size: 14px;
        margin: 10px;
        width: 90%;
        background-color: $background-color_4;
        color: $color_3;
    }
    .LinkButtonView1 {
        border: none;
        background: none;
        width: 35px;
    }
    .chatFlowWebChatVoiceButton {
        height: 30px;
        width: 45px;
        border-radius: 99px;
        background-color: #0f688b;
        margin: 0 15px 0px 0px;
        padding: 0 1rem;
    }
    .chatFlowConfirmationBox {
        border-radius: 8px;
        border: solid 1px #dadada;
        float: left;
        /*width:170px;*/
    }
    .chatFlowConfirmationBoxTitleBig {
        /* background-color: #027aaa; */
        background-color: #0f62ac;
        border-radius: 7px 7px 0px 0px;
        height: 60px;
        p {
            color: #ffffff;
            font-size: 14px;
            float: left;
            margin: 12px 15px 0 15px !important;
            overflow-wrap: break-word;
            width: 350px;
        }
    }
    .chatFlowConfirmationBoxButtonLeft {
        background-color: $background-color_3;
        border-radius: 0px 0px 0px 7px;
        height: 35px;
        @include floatLeft;
        min-width: 50px;
        width: 100%;
    }
    .chatFlowConfirmationBoxButtonLeft p,
    .chatFlowConfirmationBoxButtonCenter p,
    .chatFlowConfirmationBoxButtonRight p,
    .chatFlowConfirmationBoxButtonFull p {
        color: $color_1;
        font-size: 11px;
        @include floatLeft;
        margin: 0px 10px 0px 10px !important;
    }

    .chatFlowConfirmationBoxButtonLeft img,
    .chatFlowConfirmationBoxButtonCenter img,
    .chatFlowConfirmationBoxButtonRight img {
        height: 13px;
        @include floatLeft;
    }
    .chatFlowConfirmationBoxButtonSep {
        border-color: #dadada;
        border-style: solid;
        border-width: 0px 1px 0px 0px;
        cursor: pointer;
    }
    .chatFlowBoxCenterTextBigText12 {
        font-size: 12px !important;
    }
    .chatbot-associated-devices-list {
        overflow-y: auto;
        // height: 145px;
        margin-bottom: 10px;
    }
    .Ass_Name{
        margin-left: 10px;
        display: block;
    }
    .ChatbotCameraButton {
        width: 25px;
            height: 25px;
            object-fit: contain;
            border-radius: 25px;
            box-shadow: $box-shadow_2;
            background-color: #0b70d1;
            @include flexCenter;
            margin-left: 5px;
            img {
                // margin-right: 2px;
                height: 16px;
                margin-left: 2px;
                margin-top: 2px;
            }
    }
    .reasonCodeHeader {
        text-align: center;
        display: block !important;
        color: #767676;
        margin-left: 0px !important;
    }
    .reasonCodeBtn {
        background-color: #4CAF50;
        /* Green */
        border: none;
        /* color: white; */
        padding: 15px 15px;
        padding-right: 35px;
        text-align: left;
        text-decoration: none;
        display: inline-block;
        cursor: pointer;
        background-color: #1F1A24;
        color: #FFFFFF;
        font-size: 14px;
        border-radius: 7px 7px 7px 7px;
        margin: 7px 0px 0px 5px;
        width: 320px;
    }
    .selected-reasoncode {
        background-color: #EAF7FC;
        color: #2E92B9;
        font-weight: bold;
    }
    .checkmark-circle-reasoncode {
        position: absolute;
        top: 50%;
        left: 30%;
        transform: translate(-50%, -50%);
        float: left;
        padding: 0px !important;
        margin: 1px 0px 0px 1px;
        height: 22px;
    }
    .checkmarkcircle-visible {
        display: block;
    }
    .checkmarkcircle-hidden {
        display: none;
    }
    .chatbot-live-camera {
        // width: auto;
        width: 350px;
        height: 100%;
        padding: 6px;
        border-radius: 3px;
        box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.08);
        // background-color: #fff;
        background-color: #545454;
        margin: 10px 10px 15px 10px;
        :hover .mosaicButtonsMainDiv {
            display: flex;
            bottom: 1px;
        }
        :hover .cameraButtonsMainDiv {
            display: flex;
            bottom: 1px;
        }
        .span {
            color: white;
        }
    }
    .chatbotPlaybackCameraContainerMain {
        :hover .mosaicButtonsMainDiv {
            display: flex;
            bottom: 1px;
        }
        :hover .cameraButtonsMainDiv {
            display: flex;
            bottom: 1px;
        }
    }
    .chatbotLiveCameraContainer, .chatbotPlaybackCameraContainer {
        position: relative;
    }
    .chatbotLiveCameraName{
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 0.75rem;
        @include fontStyle;
        font-weight: 600;
        color: #8a8a8a;
        margin: 0px 0px 6px 0px;
        span {
            font-size: 0.75rem;
            @include fontStyle;
            font-weight: 600;
            // color: #4e4e4e;
            color: #ffffff;
            margin-left: 10px;
        }
    }
    .LocationNameText {
        font-size: 0.75rem;
        @include fontStyle;
        font-weight: 600;
        color: #8a8a8a;
        span {
            font-size: 0.75rem;
            @include fontStyle;
            font-weight: 600;
            color: #ffffff;
            margin-left: 10px;
        }
    }
    .chatFlowWebHeaderBottom {
        background-color: #0D70D1;
        height: 60px;
    }
    .chatFlowWebViewWorkflow {
        border-radius: 25px;
        height: 30px;
        border: 1px solid #ffffff;
        cursor: pointer;
        background-color: #0D70D1;
        p {
            float: left;
            color: #ffffff;
            font-size: 13px;
            padding: 0px !important;
            margin: 0px 0px 0px 0px !important;
        }
    }
    .TwoWayAudioToggle {
        width: 39px;
        height: 39px;
        border-radius: 22px;
        margin: 0px 6px 0px 6px;
        background: #027AAA 0% 0% no-repeat padding-box;
        border: 0.5px solid #006B96;
        box-shadow: 0 13px 10px rgb(6 50 149);
        // background: #FFFFFF 0% 0% no-repeat padding-box;
        // box-shadow: 0px 0px 3px #00000066;
        // border: 1px solid #0055A8;
        
    }
    .TwoWayAudioToggle1 {
        width: 39px;
        height: 39px;
        margin: 0px 6px 0px 6px;
        border-radius: 50%;
        // border: 0.5px solid #006B96;
        // background: #027AAA;
        // box-shadow: inset -10px -10px 20px #025d81,
        //     inset 10px 10px 20px #0297d3;
        background: #0D70D1 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 4px #00000099;
        border: 1px solid #0055A8;
    }
    .AssociateCamera {
        width: 39px;
        height: 39px;
        border-radius: 22px;
        margin: 0px 6px 0px 6px;
        box-shadow: 0px 0px 4px #00000099;
        border: 1px solid #0055A8;
    }
    .two-way-and-hooter-on-off {
        height: 39px !important;
        width: 39px !important;
        margin-top: -5px;
    }
    
    .two-way-and-hooter-on-off-span {
        float: right;
    }
    .stat-officials-icon {
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        padding: 7px;
    }
    .chatflow-page-container-div {
        height: calc(100vh - 195px);
        overflow: overlay;
    }
    .chatFlowMultiImageContainer {
        float: left;
    }
    .chatFlowMultiImageContainer img {
        float: left;
        height: 227px;
        width: 200px;
        border-radius: 0px 10px 10px 10px;
    }
    .chatFlowVoiceBoxContainer {
        padding: 5px 10px;
        float: left;
        background-color: #027aaa;
        margin: 0px 15px 0px 0px;
        border-radius: 6px;
    }
    .Associate-row {
        margin: 0px !important;
        width: 100%;
        height: calc(100% - 50px);
    }
    .resizeOneView {
        display: grid;
        grid-gap: 3px;
        grid-template-columns: repeat(1, 1fr);
        height: 100%;
        // .player-responsive1 {
        //     height: 300px !important;
        // }
        :hover .cameraButtonsMainDiv {
            display: flex;
            bottom: 1px;
        }
    }
    .resizeTwoView {
        display: grid;
        grid-gap: 3px;
        height: 100%;
        :hover .cameraButtonsMainDiv {
            display: flex;
            bottom: 1px;
        }
    }
    .resize2x2 {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-gap: 3px;
        // width: 100vw;
        // height: 99.8vh;
        overflow: hidden;
        height: 100%;
        :hover .cameraButtonsMainDiv {
            display: flex;
            bottom: 1px;
        }
    }

    @media only screen and (max-width: 600px) {
        .resize2x2 {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
        //   grid-template-rows: repeat(10, 1fr);
          grid-template-rows: 2fr;
          grid-gap: 3px;
        //   width: 100vw;
          height: 99.8vh;
          overflow: hidden;
      }
    }

    .AssoHieghtChildDiv {
        :hover .cameraButtonsMainDiv {
            display: flex;
            bottom: 1px;
        }
    }

    .resize2x2 .AssoHieghtChildDiv:nth-child(1) {
        grid-column: auto/span 3;
        grid-row: span 3;
    }

    .resize2x2 .AssoHieghtChildDiv:nth-child(2) {
        grid-column: auto/span 3;
        grid-row: span 3;
    }

    .resize2x2 .AssoHieghtChildDiv:nth-child(3) {
        grid-column: auto/span 3;
        grid-row: span 3;
    }

    .resize2x2 .AssoHieghtChildDiv:nth-child(4) {
        grid-column: auto/span 3;
        grid-row: span 3;
    }

    .title h2 {
        left: 1087px;
        width: 90%;
        text-align: left;
        font: normal normal 600 18px/14px Segoe UI;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
    }
    .emergencyDetails p, .emergencyDetails .close span, .emergencyDetails .modal-header {
        color: #fff !important;
    }
    .view-workflow-btn {
        padding-left: 150px;
        @include devices(laptop_sm) {
            padding-left: 45px;
        }
    }
    .newChatboxDiv{
		.multi-chatbot-container-div{
			height: auto;
			/* .react-functional-chatbot-main-div{
				height: calc(100vh - 550px);
			} */
		}
		.AlertFilterHeaderDiv{
			cursor: pointer;
            height: 45px;
		}
        .FireAlertTextp {
            font-weight: normal;
            color: $color_1;
            span{
                font-weight: bold;
            }
        }
        .chatFlowMobileHeaderBottom{
            position:unset;
            height: 60px;
            padding: 0px 10px;
            border-radius: 0px;
        }
        .chatFlowMobileBtmOne{
            margin: 0px;
        }
        .hooter-twoway-div{
            margin: 0px;
        }
	}
}