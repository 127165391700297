/* Health Monitoring sites css starts */

.site-card {
  width: 230px;
  height: 75px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  /* background: #ffffff 0% 0% no-repeat padding-box; */
  background: #fafafa 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #81808029;
  /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06); */
  border-radius: 12px;
  opacity: 1;
  cursor: pointer;
}

.site-1 {
  padding: 10px;
}
.img-1 {
  width: 29px;
  height: 24px;
}
.img-bg {
  width: 60px;
  height: 60px;
  /* margin: 0 39.8px 0 0; */
  padding: 15px 17px;
  border-radius: 8px;
  background-color: #def7f3;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-bg img {
  width: 100%;
}
.cardBox {
  width: 100%;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}
.cardBox .kpicard {
  background: var(--white);
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
  display: flex;
}
.kpidetailsdiv {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px; */
  display: flex;
  margin-left: 10px;
  /* width: 100%; */
  justify-content: space-between;
  flex-direction: column;
}
.devices-text {
  /* width: 107px; */
  /* height: 20px; */
  /* margin: 1px 22.8px 4px 33px; */
  font-family: Segoe UI;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #696969;
  min-width: 250px;
}
.devices-text .devices-counts {
  font-size: 15px;
  font-weight: 600;
  color: #535353;
}
.statusmaindiv {
  display: flex;
  grid-gap: 10px;
  justify-content: space-between;
}
.online-status {
  width: 29px;
  height: 14px;
  margin: 0 0 14px;
  font-family: Segoe UI;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0.01px;
  text-align: left;
  color: #1ab243;
}
.offline-status {
  width: 30px;
  height: 14px;
  margin: 0 0 14px;
  font-family: Segoe UI;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0.01px;
  text-align: left;
  color: #dd0808;
}
.ol-offline-status-count {
  width: 20px;
  height: 17px;
  margin: 11px 8px 0 1px;
  font-family: Segoe UI;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: 0.01px;
  text-align: left;
  color: #535353;
}


.Main-Base {
  /* height: 850px; */
  /* margin: 147px 17px 8px 10px;
  padding: 58px 10px 12px; */
  border-radius: 8px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.4);
  background-color: #fff;
  padding-top: 10px;
  margin-bottom: 10px;
}

.Card-Table-Navigation {
  width: 270px;
  /* height: 37px; */
  margin: 0px 10px;
  padding: 3px;
  border-radius: 7px;
  border: solid 1px #0b70d1;
  background-color: #fff;
}
.hmnav-item a {
  text-decoration: none;
}
.hmnav-item > .active {
  width: 82px;
  height: 31px;
  padding: 6px 10px;
  border-radius: 5px !important;
  background-color: #0b70d1;
  margin-left: 0px;
  text-decoration: none;
}
tbody {
  border-color: beige;
}
.devices-tablename {
  /* width: 88px; */
  /* height: 17px; */
  margin: 9px 108px 8px 0;
  font-family: Segoe UI;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  color: #585858;
}

.c-header {
  /* width: 1239px; */
  height: 60px;
  background: none;
  border-bottom: none;
  font: normal normal bold 15px/20px Nunito Sans;
}

.t-header {
  width: 74px;
  height: 15px;
  text-align: left;
  font: normal normal bold 11px/15px Nunito Sans;
  /* font-family: 'Nunito Sans', sans-serif; */
  letter-spacing: 0px;
  color: #131415;
  border-bottom: hidden;
  opacity: 1;
}
.txt-table-style {
  /* display: block; */
  overflow: auto;
  scroll-behavior: smooth;
  table-layout: fixed;
  /* height: calc(100vh - 340px); */
  border: 1px solid #d4d4d4;
}


.horizontal-Line {
  /* width: 1166px; */
  height: 0;
  margin: 12px 14px 0 14px;
  border: solid 1px #ebebeb;
}

.viewTimelineMsg {
  display: flex;
  justify-content: center;
  flex-direction: row;
  grid-gap: 10px;
  align-items: center;
  height: 300px;
}
/* Health Monitoring sites css ends */
