$color_1: #8a8a8a;
$color_2: #BFBFBF;
$color_3: #4a595f;
$color_4: #fff;
$color_5: #b8b8b8;
$color_6: #bababa;
$background-color_1: #1F1A24;
$background-color_2: #eff5f8;
$background-color_3: #ffffff;
$background-color_4: #292830;
$background-color_5: transparent;
$background-color_6: #504e57;
$background-color_7: #484848;
$box-shadow_1:rgb(112 111 111) 0px 0px 5px 0px inset, 0 0 6px 0 rgba(0, 0, 0, 0.3);

.darkTheme{
    .ReportAccordianTitleIcon {
        float: left;
    }
    .ReportAccordionMainContainer {
        padding-bottom: 1px;
        @include CardBoxBGShadow;
        box-shadow: $box-shadow_1;
        background-color: $background-color_1;
    }
    .ReportAccordianArrow {
        float: right;
    }
    .ReportRightOptions {
        float: right;
        img {
            float: left;
            margin: 0px 5px 0px 0px;
        }
        p {
            float: left;
            font-size: 12px;
            color: $color_2;
            padding: 0px;
            margin: 0px;
        }
    }
    .ReportSubTitleText {
        float: left;
        font-size: 12px;
        color: $color_2;
        padding-left: 15px;
        margin: 0px;
    }
    .ReportTableAlertSeverity {
        font-weight: bold;
    }
    .ReportTableFontBold {
        font-weight: bold;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .ReportTableHeadReport{
        .SearchViewInput{
            background-color: $background-color_5;
        }
    }
    .custom-dd {
        width: 14.125rem;
        height: 2.375rem;
        margin: 0.938rem 31.375rem 0.313rem 1.25rem;
        padding: 0.75rem 0.628rem 0.625rem 0.636rem;
        border-radius: 8px;
        box-shadow: 0 3px 15px 0 #00000014;
        border: solid 1px #a6c0c9;
        background-color: $background-color_3;
        &:focus {
            outline: none;
        }
    }
    .custom-dd-text {
        margin: 0 5.174rem 0 0;
        font-size: 0.75rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: $color_3;
    }
    .ReportAccordionHeader {
        margin: 0.6rem 0;
        padding: 0.937rem 1.957rem 0.813rem 0.983rem;
        width: 100%;
        border: none;
        &:focus {
            outline: none;
        }
    }
    .report-title-head {
        display: inline-flex;
        width: 100%;
    }
    .report-table {
        margin-bottom: 0px !important;
        th {
            border-bottom: none !important;
            border-top: none !important;
            color: $color_5;
        }
        tbody {
            >tr {
                &:last-child {
                    >td {
                        border-bottom: 0 !important;
                    }
                }
            }
        }
    }
    .ReportAccordianTitleText {
        float: left;
        h5 {
            color: $color_6;
        }
    }
    .profile-name {
        // height: 1.125rem;
        font-size: 0.813rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: $color_6;
    }
    .inputSearchLabelContainer {
        position: relative;
        /* height: 35px; */
        border-radius: 7px;
        border: 1px solid #bababa;
        background-color: transparent;
        width: 350px;

    }
    .CalenderDiv {
        width: 44%;
        display: inline-block;
        margin-top: 7px;
        border: 1px solid #bababa;
        border-radius: 7px;
        margin-left: 10px;        
    }
    .SearchViewInput.cursor-ptr {
        margin-left: 1rem;
        width: 90%;
    }
    .EventReportTable tbody {
        height: calc(100vh - 372px);
        @include devices(laptop_xs) {
            height: calc(100vh - 375px);
        }
    }    

    .table-wrapper {
        // width: 1675px;
        width: 99%;
        overflow: auto;
        margin: 8px;
        table {
            // width: 115vw;
        }
        thead {
            background-color: #070612;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            border-top: 0px;
            // width: 100%;
            font-size: 0.8rem;
            display: table;
            table-layout: fixed;
            th {
                min-width: 300px;
                border: 0px;
            }
        }
        tbody {
            height: 545px;
            overflow: auto;
            display: block;
            table-layout: fixed;
            // border: 1px solid #d4d4d4;
            width: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            @include devices(laptop_xl) {
                height: 530px;
            }
            @include devices(laptop_lg) {
                height: 380px;
            }
            @include devices(laptop_md) {
                height: 380px;
            }
            @include devices(laptop_sm) {
                height: 250px;
            }
            tr:nth-child(even) {
                background-color: $background-color_6;
        }
        }
        tbody td {
            cursor: pointer;
            font-size: 14px;
            font-family: Segoe UI;
            font-weight: normal;
            line-height: 1.36;
            letter-spacing: normal;
            text-align: left;
            // padding-left: 16px !important;
            padding: 1rem;
            min-width: 300px;
            width: 100%;
            color: #BABABA;
            border-top: none !important;
            word-break: break-all;
            // @include devices(laptop_lg) {
            //     min-width: 272px;
            // }
        } 
        tbody tr:hover {
            background-color: $background-color_7;
        }
    }

    .ReportTableHeadReport th {
        .tableHeaderTooltip {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 270px; 
        }
    }

    .TabulerReportMainDiv {
        margin: 20px 10px 15px 10px;
        @include floatLeft;
        @include CardBoxBGShadow;
        // box-shadow: $box-shadow1;
        background: $background-color_1;
        width: 99%;
        table{
            height: 645px;
        }
    }

    .DeviceCurrentStatusDiv .EventReportTable td {
        font-size: 14px;
        font-family: Segoe UI;
        font-weight: normal;
        line-height: 1.36;
        letter-spacing: normal;
        text-align: left;
        padding-left: 20px !important;
        cursor: default !important;
    }
    .HeaderSpanHR{
        margin: 15px;
        font-size: 15px;
        font-weight: 500;
        color: $color_4;
    }    
    .HMHeader .btn-outline-primary {
        height: 32px !important;
        line-height: 1;
    }
    .inputTypeSearch {
        border: 0px !important;
        background: none !important;
        font-size: 13px;
        /* line-height: 29px; */
        color: $color_4;
        /* margin: 5px 40px 5px 5px !important; */
        width: 90%;
        height: 35px;
        padding-left: 7px;
        &::placeholder {
            color: $color_1;
            font-size: 13px;
            line-height: 18px;
        }
    }
    .dropdown-menu{
        background-color: #747474;
        .dropdown-item{
            color: $color_4;
        }
    }
    
    .rs-calendar-time-dropdown {
        background-color: black;
        color: #ffffff;
    }
    .rs-calendar-time-dropdown-column-title {
        background-color: black;
        color: #ffffff;
    }
}