$color_1: #c5c5c5;
$color_2: #343434;
$color_3: #fff;
$color_4: #292929;
$color_5: #bcbcbc;
$color_6: #333;
$font-family_1: Segoe UI;
$font-family_2: "Glyphicons Halflings";
$background-color_1: transparent;
$background-color_2: #fff;
$background-color_3: #f2f6fe;
$background-color_4: #f5f5f5;
$background-color_5: #7e7e7e;
$background-color_6: #0b70d1;
$background-color_7: #eee;
$border-color_1: #e4e5e7;

.lightTheme{
    .Notification-form {
      overflow: auto;
      overflow-x: hidden;
      max-height: calc(100vh - 215px);
      text-align: center;
      padding: 0px 35px 2px;
      float: left;
      width: 100%;
      @include devices(laptop_xl) {
        // max-height:  ;
      } 
      @include devices(laptop_lg) {
        // max-height:  ;
      }  
      @include devices(laptop_md) {
        // max-height:  ;
      }  
      @include devices(laptop_sm) {
        // max-height:  ;
      } 
      @include devices(laptop_xs) {
        max-height: calc(100vh - 156px);
      }
      p {
        margin-bottom: 0px !important;
      }
      &::placeholder {
        font-family: $font-family_1;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.15;
        letter-spacing: normal;
        text-align: left;
        color: $color_1;
      }
      .card {
        border: 1px solid #84a5c4;
      }
      .SiteNameText {
        font-family: $font-family_1;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: -0.09px;
        text-align: left;
        color: $color_4;
        @include devices(laptop_sm) {
          font-size: 12px;
        }
        @include devices(laptop_xs) {
          font-size: 11px;
        }
      }
    }
    .TemplateHgtDiv{
       height: 800px;
      //height: auto;
    }
    .NotificationList {
      height: 731px;
      margin: 10px;
      overflow: auto;
      padding-right: 8px;
      scroll-behavior: smooth;
      @include devices(laptop_xl) {
        height: 704px ;
      } 
      @include devices(laptop_lg) {
        height: 550px ;
      }  
      @include devices(laptop_md) {
        height: 550px ;
      }  
      @include devices(laptop_sm) {
        height: 420px ;
      }  
      @include devices(laptop_xs) {
        height: calc(100vh - 215px);
      }
    }
    .MessageTextBox {
      height: 2.5rem;
      border-radius: 8px;
      border: 1px solid #bebebe;
      padding-left: 8px;
      padding-right: 8px;
      font-size: 13px;
      text-align: left;
      width: 100%;
      background-color: $background-color_1;
      &::placeholder {
        font-family: $font-family_1;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: $color_1;
      }
    }
    .rmsc .dropdown-container:focus-within {
      box-shadow: var(--rmsc-main) 0 0 0 0px !important;
      border-color: var(--rmsc-main);
  }
  .Notification-form, .AlertActivity-form  .rmsc {
      .dropdown-container {
        border-radius: 8px !important;
        // height: 2.5rem !important;
        // border: 1px solid #bebebe;
        // background-color: $background-color_2;
        background-color: transparent;
        border: 0px !important;
        outline: 0px !important;
      }
      .dropdown-heading {
        &:focus {
          outline: red !important;
        }
        .dropdown-heading-value {
          text-align: left !important;
          font-family: $font-family_1;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: -0.08px;
          color: $color_5;
        }
      }
    }
    .MessageTextBox1 {
      height: 2.5rem;
      border-radius: 8px;
      border: 1px solid #bebebe;
      padding-left: 8px;
      padding-right: 8px;
      font-size: 13px;
      text-align: left;
      width: 100%;
      // margin-bottom: 15px;
      &::placeholder {
        font-family: $font-family_1;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: $color_1;
      }
    }
    .NotificationMsgBox {
      border-radius: 8px;
      border: 1px solid #bebebe;
      padding-left: 8px;
      padding-right: 8px;
      font-size: 13px;
      text-align: left;
      width: 100%;
      margin-bottom: 15px;
      &::placeholder {
        font-family: $font-family_1;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: $color_1;
      }
    }
    .Notificationgrid-container {
      float: left;
      width: 100%;
      gap: 10px;
      padding-right: 1px;
      margin-bottom: 20px;
    }
    .NotificationTextBox {
      width: 100%;
      float: left;
      margin-top: 17px;
    }
    .spocCheckbox {
      padding: 0.5rem 0.25rem;
      border: 1px solid #84a5c4;
      background-color: $background-color_3;
      float: left;
      width: 100%;
      margin: 20px 0px;
    }
    .searchNotification {
      padding-left: 5px;
      width: 95% !important;
    }
    .startDiv {
      input {
        &::placeholder {
          font-family: $font-family_1;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          letter-spacing: normal;
          text-align: left;
          color: $color_1;
        }
      }
      input[disabled] {
        color: $color_5;
      }
    }
    textarea {
      overflow: unset !important;
      &::placeholder {
        color: $color_1;
      }
    }
    .submenu {
      font-size: 14px;
      list-style-type: none;
      text-align: start;
      padding: 0px;
      margin-left: 7px;
      margin-right: 6px;
      line-height: 2;
      li {
        padding-left: 5px;
        &:nth-of-type(odd) {
          background-color: $background-color_2 !important;
        }
        &:nth-of-type(even) {
          background-color: $background-color_4;
        }
      }
    }
    .NoRecordFound {
      height: 345px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .SearchNotification {
      float: left;
      border-radius: 8px;
      background-color: $background-color_2;
      padding-left: 10px;
      width: 68%;
      margin-left: 0px;
      padding-right: 10px;
      margin-top: 5px;
      .SearchViewInput {
        border-radius: 8px;
        background-color: $background-color_2;
        border: none;
        height: 35px;
        padding: 5px;
        width: 94%;
        font-size: 0.75rem;
        @include devices(laptop_lg) {
          width: 92%;
        }
        @include devices(laptop_md) {
          width: 92%;
        }  
        @include devices(laptop_sm) {
          width: 91% !important;
        }
        @include devices(laptop_xs) {
          width: 90% !important;
        }
      }
    }
    .NotificationEmailMsgBox {
      width: 100%;
      height: 210px;
      margin: 2px 0 0;
      border-radius: 5px;
      border: solid 1px #bebebe;
      float: left;
      margin-bottom: 20px;
    }
    .EmailMsgBoxHeading {
      height: 45px;
      border-bottom: 1px solid #bebebe;
      padding: 7px;
    }
    .EmailNotificationNavItem {
      height: 30px;
      margin-left: 0.6rem;
      border-radius: 15.5px !important;
      border: 1px solid #0b70d1;
      margin-top: 0.01rem;
      .inactive-text-dashboard {
        margin-left: 0px;
      }
      >.active {
        height: 28px;
        padding: 0.2rem 0.2rem;
        border-radius: 15.5px !important;
        background-color: $background-color_6 !important;
        label {
          color: $color_3;
        }
      }
      label {
        font-family: $font-family_1;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: $color_2;
      }
      .active {
        .Notificationcheckwithlabel {
          margin-top: 1px;
        }
      }
    }
    .EmailNotificationNavItem[disabled] {
      border: 1px solid #7e7e7e;
      >.active {
        background-color: $background-color_5 !important;
      }
    }
    .EmailMsgSubject {
      border: none;
      padding-left: 8px;
      padding-right: 8px;
      font-size: 13px;
      text-align: left;
      width: 100%;
      margin-top: 15px;
      &::placeholder {
        font-family: $font-family_1;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: $color_1;
      }
    }
    .EmailMsgDetail {
      border: none;
      padding-left: 8px;
      padding-right: 8px;
      font-size: 13px;
      text-align: left;
      width: 100%;
      &::placeholder {
        font-family: $font-family_1;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: $color_1;
      }
    }
    .EmailDivideLine {
      width: 98%;
      height: 0;
      margin: 8.5px 9px 0;
      border: solid 1px #bebebe;
    }
    .NotificationUserTable {
      width: 100% !important;
      margin-left: 0px !important;
      tbody {
        min-height: 100px !important;
        max-height: 300px !important;
        height: auto !important;
      }
    }
    .selectTabsCheckbox {
      vertical-align: text-bottom;
      display: inline-block;
      margin-right: 4px;
      border: 1px solid #707070;
    }
    .Notificationcheckwithlabel {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 4px;
    }
    .deleteCustomContact {
      border-radius: 6px;
      float: right;
      width: max-content;
      background-color: $background-color_2;
      margin-top: 13px;
      padding-right: 2px;
    }
    .customContactSearch {
      width: 95%;
      @include devices(laptop_lg) {
        width: 94%;
      }
      @include devices(laptop_md) {
        width: 93%;
      }  
      @include devices(laptop_sm) {
        width: 92%;
      }
    }
    .noRegisterFound {
      margin: 35px auto 0 auto;
      width: 130px;
    }
    .mentionDiv {
      >div {
        >div {
          >div {
            height: 120px !important;
          }
        }
      }
      textarea[disabled] {
        color: $color_5;
      }
    }
    .card-header {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      font: normal normal 600 16px/21px Segoe UI;
      letter-spacing: -0.11px;
      color: $color_4;
      opacity: 1;
      border-bottom: none !important;
    }
    .panel-default {
      >.panel-heading {
        color: $color_6;
        background-color: $background-color_2;
        border-color: $border-color_1;
        padding: 0;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        a {
          display: block;
          padding: 10px 15px;
          &:after {
            content: "";
            position: relative;
            top: 1px;
            font-family: $font-family_2;
            font-style: normal;
            font-weight: 400;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            float: right;
            transition: transform 0.25s linear;
            -webkit-transition: -webkit-transform 0.25s linear;
          }
        }
        a[aria-expanded="true"] {
          background-color: $background-color_7;
          &:after {
            content: "\2212";
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
          }
        }
        a[aria-expanded="false"] {
          &:after {
            content: "\002b";
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
          }
        }
      }
    }
    .NotificationCardAccordion{
      background-color:  $background-color_3;
    }
}