$color_1: #182e37;
$color_2: #c5c5c5;
$color_3: #6c6c6c;
$color_4: #4e4e4e;
$color_5: #0f688b;
$color_6: #4A4A4A;
$color_7: #BBBBBB;
$color_8: #535353;
$color_9: #84a5c4;
$color_10: #027aaa;
$color_11: #397ff4;
$color_12: #b3614b;
$color_13: #e6e6e6;
$color_14: #454545;
$color_15: #dee2e6;
$color_16: #0b70d1;
$color_17: #ffffff;
$font-family_1: Arial;
$background-color_1: #fff;
$background-color_2: transparent;
$background-color_3: #f0f3f5;
$background-color_4: #0b70d1;
$background-color_5: #7e7e7e;
$background-color_6: #eff5f8;
$background-color_7: #f5f6f6;
$background-color_8: #c4d8df;
$background-color_9: #f2f6fe;
$background-color_10: #dbe6fc;
$background-color_11: #bcbcbc;

:root {
	--cardWidth: 150px;
	--cardHeight: 75px;
	--cardMargin: 16px;
	--cardZoneWidth: 85px;
	--cardZoneHeigth: 26px;
	--cardZoneMargin: 8px;
}

.lightTheme {
	label {
		margin-bottom: .1rem;
		font-size: 0.800rem;
		font-weight: 500;
		text-align: left;
		color: $color_3;
	}

	.NameHeading1 {
		@include floatLeft;

		p {
			font-size: 1.5rem;
			font-weight: bold;
			text-align: left;
			color: $color_1;
			margin-bottom: 0px;
		}
	}

	.config-tool-head {
		margin: 0;
		padding-left: 5px;
	}

	.add-cameras {
		@include floatRight;
	}

	.SiteMainContainer {
		width: 100%;
		@include display-flex;
		height: 100%;
	}

	.SiteLeftContainer {
		width: 30%;
		//margin-right: 5px;
		@include floatLeft;
		@include CardBoxBGShadow;

		.SearchViewInput1 {
			@include devices(laptop_xxl) {
				width: 92%;
			}

			@include devices(laptop_xl) {
				width: 92%;
			}

			@include devices(laptop_lg) {
				width: 91%;
			}

			@include devices(laptop_md) {
				width: 89%;
			}

			@include devices(laptop_sm) {
				width: 88%;
			}
		}
	}

	.SiteRightContainer {
		width: 70%;
		margin-left: 15px;
		@include relativePosition;
		@include floatLeft;
		@include CardBoxBGShadow;
	}

	.siteformContainer {
		height: 66vh;
		overflow: scroll;

		@include devices(laptop_xxl) {
			height: 68vh;
		}

		@include devices(laptop_xl) {
			height: 65vh;
		}

		@include devices(laptop_lg) {
			height: 59vh;
		}

		@include devices(laptop_md) {
			height: 59vh;
		}

		@include devices(laptop_sm) {
			height: 47vh;
		}

		@include devices(laptop_xs) {
			height: calc(100vh - 315px);
		}
	}

	.userformContainer {
		height: 73vh;
		overflow: scroll;

		@include devices(laptop_xxl) {
			height: 68vh;
		}

		@include devices(laptop_xl) {
			height: 72vh;
		}

		@include devices(laptop_lg) {
			height: 66vh;
		}

		@include devices(laptop_md) {
			height: 67vh;
		}

		@include devices(laptop_sm) {
			height: 58vh;
		}
		@include devices(laptop_xs) {
			height: calc(100vh - 252px);
		}
	}

	.StepperSiteList {
		height: 70vh;
		margin: 10px;
		overflow: auto;
		padding-right: 8px;
		scroll-behavior: smooth;

		@include devices(laptop_xxl) {
			height: 65vh;
		}

		@include devices(laptop_xl) {
			height: 69vh;
		}

		@include devices(laptop_lg) {
			height: 63vh;
		}

		@include devices(laptop_md) {
			height: 63vh;
		}

		@include devices(laptop_sm) {
			height: 55vh;
		}

		@include devices(laptop_xs) {
			height: calc(100vh - 285px);
		}
	}

	.UserLeftList {
		height: 76vh;
		margin: 10px;
		overflow: auto;
		padding-right: 8px;
		scroll-behavior: smooth;

		@include devices(laptop_xxl) {
			height: 65vh;
		}

		@include devices(laptop_xl) {
			height: 76vh;
		}

		@include devices(laptop_lg) {
			height: 71vh;
		}

		@include devices(laptop_md) {
			height: 71vh;
		}

		@include devices(laptop_sm) {
			height: 64vh;
		}
		@include devices(laptop_xs) {
			height: calc(100vh - 222px);
		}
	}

	.HeaderSiteList {
		border: 1px solid #ccc;
		height: 40px;
		border-radius: 7px;
		padding: 5px;
	}

	.withFilterBox {
		width: 85%;
	}

	.SearchViewInput1 {
		border-radius: 8px;
		background-color: $background-color_2;
		border: none;
		width: 93%;
		font-size: 0.75rem;
		padding-left: 10px;

		&::placeholder {
			font-family: $font-family_1;
			font-size: 13px;
			line-height: 1.15;
			color: $color_2;
			@include fontStyle;
		}

		&:focus {
			outline: none;
		}
	}

	.SearchTextDivConfigTool {
		@include floatRight;
		background-color: $background-color_1;
		margin-top: 0px;
		padding-right: 10px;
	}

	.OneList {
		height: 50px;
		border-radius: 7px;
		border: solid 0.5px $background-color_4;
		padding: 10px;
		padding-left: 12px;
		margin-bottom: 5px;
		@include flexSpaceBetween;

		&.active, &:hover {
			cursor: pointer;
			transition: 0.3s;
			background-color: #f2f6fe;
			border: 2px solid $background-color_4;
			&:before {
				transform: scaleX(1.03) scaleY(1.13);
				transition: 0.3s;
			}
		}
		// &:before {
		// 	content: "";
		// 	width: 100%;
		// 	height: 100%;
		// 	position: absolute;
		// 	background-color: #FCC92E;
		// 	left: 0;
		// 	top: 0;
		// 	border-radius: 15px;
		// 	z-index: 1;
		// 	transform: scaleX(0.85) scaleY(0.85);
		// 	transition: 0.3s;
		// }
	}

	.ListofText {
		font-size: 0.875rem;
		color: $color_1;
		margin-bottom: 0px;
		@include fontStyle;
		font-weight: bold;

		@include devices(laptop_sm) {
			font-size: 0.7rem;
		}

		@include devices(laptop_md) {
			font-size: 0.8rem;
		}
	}

	.SiteNameText {
		font-size: 0.800rem;
		font-weight: 500;
		text-align: left;
		color: $color_3;
	}

	.MainHeaderName {
		font-size: 1rem;
		font-weight: 900;
		text-align: left;
		color: $color_4;
		border-bottom: 1px solid $color_13;
		padding: 16px;
	}

	.site-form {
		overflow: auto;
		overflow-x: hidden;
		// max-height: calc(100vh - 230px);
		// height: ;
		text-align: center;
		padding: 15px 250px 0px;

		@include devices(laptop_xl) {
			padding: 15px 170px 0px;
		}

		@include devices(laptop_lg) {
			padding: 15px 150px 0px;
		}

		@include devices(laptop_md) {
			padding: 15px 110px 0px;
		}

		@include devices(laptop_sm) {
			padding: 15px 75px 0px;
		}
	}

	.SiteTextBox {
		width: auto;
		margin-left: 7px;
		margin-right: 7px;
		margin-top: 17px;
		@include floatLeft;

		.LocationInput {
			width: 90%;
		}
	}

	.customerDropdown {
		@include floatLeft;
		width: 83%;
		padding-left: 15px;

		@include devices(laptop_lg) {
			width: 81%;
		}

		@include devices(laptop_md) {
			width: 78%;
		}

		@include devices(laptop_sm) {
			width: 76%;
		}
	}

	.SiteNameTextBox {
		height: 2.5rem;
		width: 100%;
		border-radius: 8px;
		background-color: $background-color_3;
		border: none;
		padding-left: 8px;
		padding-right: 8px;
		font-size: 13px;
		text-align: left;
		width: 90%;
		margin-bottom: 15px;

		&::placeholder {
			font-family: $font-family_1;
			font-size: 13px;
			line-height: 1.15;
			color: $color_2;
			@include fontStyle;
		}

		:focus {
			outline: none;
		}
	}

	.phoneNumberTextBox {
		width: 81%;
		border-bottom-left-radius: 0px;
		border-top-left-radius: 0px;
		@include floatLeft;
	}

	.SelectedList {
		border: solid 2px #0b70d1;
		background: $background-color_9;
	}

	.DataAccessEdit {
		width: 32px;
		height: 32px;
		padding: 8px 7.6px 7.6px 8px;
		border-radius: 5px;
		background-color: $background-color_4;
		line-height: 10px;
		margin-left: 5px;

		// -ms-transform: translateY(-50%);
        // transform: translateY(-50%);
		// -webkit-transition-duration: 0.4s; /* Safari */
		// transition-duration: 0.4s;
		// text-decoration: none;
		// overflow: hidden;

		// border: none;
		// border-radius: 2px;
		// padding: 12px 18px;
		// font-size: 16px;
		// text-transform: uppercase;
		// cursor: pointer;
		// color: white;
		// box-shadow: 0 0 4px #999;
		// outline: none;
		// background-position: center;
		// transition: background 0.2s;

		&:hover {
			// background-color: #005aad;
			// transition: 0.5s;
			// cursor: pointer;

			background: #005aad radial-gradient(circle, transparent 1%, #005aad 1%) center/15000%;
  			transition: background 0.8s;
		}
		&:active {
			background-color: #ffffff;
			background-size: 100%;
			transition: background 0s;
		  }
	}

	@keyframes background {
		0% {
		  transform: scale(0);
		}
		100% {
		  transform: scale(4);
		}
	  }

	input {
		.SiteNameTextBox {
			&:disabled {
				opacity: 0.7;
			}
		}
	}

	.addCustomer {
		border-radius: 6px;
		@include floatLeft;
		height: 40px;
		border: none;
		line-height: 14px;
		padding-left: 15px;
		padding-right: 15px;
		width: max-content;
		background-color: $background-color_4;
		&:hover {
			// background-color: #005aad;
			// transition: 0.5s;
			// cursor: pointer;

			background: #005aad radial-gradient(circle, transparent 1%, #005aad 1%) center/15000%;
  			transition: background 0.8s;
		}
		&:active {
			background-color: #ffffff;
			background-size: 100%;
			transition: background 0s;
		  }
	}

	.addCustomerDisabled[disabled] {
		background-color: $background-color_5;
		border-radius: 6px;
		@include floatLeft;
		height: 40px;
		border: none;
		line-height: 14px;
		padding-left: 15px;
		padding-right: 15px;
		width: max-content;
	}

	.buttonDisable[disabled] {
		background-color: $background-color_11;
		position: relative;
        width: 50px;
        height: 35px;
        line-height: 10px;
        border-radius: 7px;
        opacity: 1;
        margin-left: 5px;
	}
	.DAGAddNode[disabled] {
		width: 32px;
		height: 32px;
		padding: 8px 7.6px 7.6px 8px;
		border-radius: 5px;
		background-color: $background-color_11;
		line-height: 10px;
		margin-left: 5px;
		float: right;
	}

	.DevicesHeader {
		background-color: $background-color_6;
		height: 50px;
		padding-left: 20px;
		@include flexCenter;
		justify-content: flex-start;

		p {
			font-size: 0.875rem;
			color: $color_4;
			@include fontStyle;
			font-weight: bold;
		}
	}

	.site-group {
		width: 40%;
		padding-bottom: 15px;
		@include floatLeft;
	}

	.site-group-list {
		background-color: $background-color_7;
		overflow-y: auto;
		padding-right: 20px;
		padding-left: 20px;
		max-height: 605px;
		@include devices(laptop_xs) {
			height: calc(100vh - 324px);
		}
	}

	.AddMore {
		display: inline-flex;
		width: 100%;
	}

	.UserAddPlus {
		font-size: 14px;
		padding-left: 20px;
		color: $color_5;
		margin-top: 20px;
		font-weight: 600;
		margin-top: 20px !important;
	}

	.UserAddPlus.mt-1 {
		margin-top: 20px !important;
	}

	.ConfigUserToSite {
		margin-top: 25px;
	}

	.configureUserForm-container {
		overflow: auto;
		height: auto;
		padding-bottom: 15px;
		min-height: 300px;
	}

	.cameraList {
		@include flexSpaceBetween;
		height: 45px;
		border-bottom: 1px solid $color_13;

		p {
			margin-right: 8px;
			font-size: 0.75rem;
			@include fontStyle;
			color: $color_4;
			display: inline-block;
			word-break: break-word;
			word-wrap: break-word;
		}
	}

	.ManageSite {
		margin-top: 0px;
		width: 100%;
		@include floatLeft;
		@include relativePosition;
		@include CardBoxBGShadow;
	}

	.RightListSide {
		width: 33%;
		@include floatRight;
		height: calc(100vh - 160px);
		margin-bottom: 5px;
		margin-right: 5px;
		@include CardBoxBGShadow;
		@include devices(laptop_xs) {
			height: calc(100vh - 154px);
		}
	}

	.selected-user {
		background-color: $background-color_8;
		border-left: 4px solid #25688b;
		border-bottom: none;
	}

	.stepperText {
		font-size: 14px;
		font-weight: 500;
		color: $color_6;
	}

	.StepperTextDisable {
		color: $color_7;
		font-size: 14px;
		font-weight: 500;
	}

	.AddSite {
		width: 100%;
		padding-bottom: 10px;
		@include floatLeft;
		@include relativePosition;
		@include CardBoxBGShadow;
	}

	.alert-template-fullDay-textbox {
		@include flexSpaceBetween;
		align-content: center;
		flex-direction: row;
		flex-wrap: wrap;
		height: 2.5rem;
		width: 100%;
		border-radius: 8px;
		background-color: $background-color_3;
		border: none;
		padding-left: 5px;
		font-size: 13px;
		text-align: left;
		width: 100%;
		padding-right: 18px;
		padding-bottom: 3px;
	}

	.description-textbox {
		background-color: $background-color_1;
	}

	.AddUser {
		height: calc(100vh - 180px);
		@include CardBoxBGShadow;

		.NextMoveButton {
			margin-right: 16px;
			margin-bottom: 12px;
		}

		@include devices(laptop_lg) {
			height: calc(100vh - 155px);
		}
		@include devices(laptop_xs) {
			height: calc(100vh - 149px);
		}
	}

	.wf-list-item {
		width: 92%;
		height: 3.313rem;
		margin: 0.719rem 1.111rem 0.5rem 1.25rem;
		padding: 0.812rem 1.25rem 0.75rem 0.813rem;
		border-radius: 8px;
		border: solid 1px #bcbdbe;
		background-color: $background-color_1;
		display: inline-flex;
		align-items: center;

		&:hover {
			cursor: pointer;
			box-shadow: 0 0 11px rgba(10, 10, 10, .2);
		}
	}

	.wf-list {
		overflow-y: scroll;
		scroll-behavior: smooth;
		height: calc(100vh - 312px);
		border-bottom: 1px solid $color_13;
		
		@include devices(laptop_lg) {
			height: calc(100vh - 280px);
		}
	}

	.ContactDropdown {
		width: 10%;
		border-bottom-right-radius: 0px;
		border-top-right-radius: 0px;
		border-top-left-radius: 7px;
		border-bottom-left-radius: 7px;
		padding: 5px;
		@include floatLeft;
		height: 35px;
		border-right: 1px solid $color_13;
		// @include devices(laptop_xl) {
		// 	width: 10%;
		// }
		// @include devices(laptop_sm) {
		// 	width: 13%;
		// }
	}

	.contactinputfield {
		width: calc(100% - 118px) !important;

		// width: calc(100% - 80px) !important;
		@include devices (laptop_xl) {
			width: calc(100% - 138px) !important;
		}

		@include devices(laptop_lg) {
			width: calc(100% - 130px) !important;
		}

		@include devices(laptop_sm) {
			width: calc(100% - 110px) !important;
		}
	}

	.MainDropdownStyle {
		background: $background-color_3;
	}

	.DataAccessDiv {
		height: 53px;
		@include flexSpaceBetween;
		height: 2.5rem;
		border-radius: 8px;
		background-color: $background-color_3;
		border: none;
		padding-left: 5px;
		padding-right: 5px;
		font-size: 13px;
	}

	.data-access-nonedit {
		.react-flow {
			background-color: $background-color_1 !important;
		}

		.react-flow__node-default {
			border: solid 1px #707070 !important;
			background-color: $background-color_1 !important;
			box-shadow: none !important;
		}

		.NodeHeading {
			color: $color_1 !important;
		}
	}

	.NodeHeading {
		@include NodeHeadingFont;
		margin-bottom: 0.2rem;
		padding-top: 0.2rem;
		color: #000;
		overflow-wrap: break-word;
	}

	.NodeDescreption {
		@include NodeHeadingFont;
		margin-bottom: 0rem;
		color: $color_8;
	}

	.BoxShadowDiv {
		border-radius: 7px;
		box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
	}

	.inputTypeLabelContainerDag {
		position: relative;
		height: 41px;
		border-radius: 7px;
		border: 1px solid #ccc;
	}

	.inputTypeLabelContainerDag {
		label {
			@include absolutePosition;
			font-size: 14px;
			font-weight: normal;
			color: #5f6368;
			background-color: $background-color_1;
			padding: 0px 4px;
			top: -10px;
			left: 8px;
			margin: 0px !important;
			line-height: 16px;
		}
	}

	.inputType {
		border: 0px !important;
		background-color: unset !important;
		font-size: 16px;
		// line-height: 20px;
		line-height: 35px;
		color: #202124;
		// margin: 10px 10px 6px 10px !important;
		// width: calc(100% - 20px);
		width: 100%;
		padding: 0px 5px;
		margin-left: 5px;

		// 	height: 2.5rem;
		//   border-radius: 8px;
		//   border: 0px solid #bebebe;
		//   padding-left: 8px;
		//   padding-right: 8px;
		//   font-size: 13px;
		//   text-align: left;
		//   width: 100%;
	}
	.inputLoginType {
        border: 0px !important;
        background-color: unset !important;
        font-size: 16px;
        line-height: 35px;
        color: #202124;
        width: 100%;
        padding: 0px 5px;
        margin-left: 5px;
    }

	.customerInfoDiv {
		width: 180px;
		height: 65px;
		margin: 10px;
		padding: 5.5px 5px 5px 9px;
		border-radius: 5px;
		box-shadow: 0 0 6px 0 rgba(57, 127, 245, 0.3);
		background-color: $background-color_9;
		border: solid 1px #397ff5;
		display: flex;
		justify-content: space-between;
		align-content: space-around;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.customerFilter {
		@include absolutePosition;
		left: 10rem;
		top: 2rem;
		z-index: 9999;
		border: solid 1px #b7b7b7;
		border-radius: 5px;
		background-color: $background-color_1;
		width: 205px;
		box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
	}

	.dataAccessSearchInput {
		border-radius: 8px;
		background-color: $background-color_1;
		border: none;
		padding: 6px 0px;
		width: 88%;
		font-size: 0.75rem;

		@include devices(laptop_lg) {
			width: 85%;
		}

		@include devices(laptop_md) {
			width: 85%;
		}

		@include devices(laptop_sm) {
			width: 83%;
		}
		@include devices(laptop_xs) {
			width: 82%;
		}

		&::placeholder {
			font-family: $font-family_1;
			font-size: 13px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.15;
			letter-spacing: normal;
			text-align: left;
			color: $color_1;
		}
	}

	.ViewUpdate {
		font-size: 0.875rem;
		font-weight: normal;
		text-align: right;
		color: #fff;
	}

	.SearchTextDiv {
		@include floatLeft;
		border-radius: 8px;
		background-color: #fff;
		padding-left: 10px;
		margin-top: 7px;
		margin-left: 107px;
		width: 145px;
	}

	.StatusBtn {
		height: 1.563rem;
		padding: 0.313rem 0rem 0.25rem 0rem;
		border-radius: 12px;
		text-align: center;
		display: block;
		font-size: 0.75rem;
		font-weight: bolder;
		background-color: transparent !important;
		@include floatLeft;
	}

	.SiteButtonDiv {
		width: 60%;
		height: 45px;
		@include floatLeft;
		display: flex;
		align-content: center;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: center;
	}

	.SiteButton {
		width: 104px;
		height: 28px;
		margin: 0 0.219rem 0 0.219rem;
		border-radius: 14px;
		background-image: linear-gradient(244deg, #0f62acc9 26%, #0f62ac);
		border: none;
	}

	.SiteButtonText {
		font-size: 0.75rem;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #fff;
	}

	.TemplateProgress {
		font-size: 0.75rem;
		font-weight: bold;
		@include fontStyle;
		color: #748b94;
		margin-bottom: 0px;
		display: inline-block;
	}

	.config-templateprogress {
		padding: 0;
		border: none;
		background: none;
		font-size: 0.75rem;
		font-weight: bold;
		@include fontStyle;
		color: #748b94;
		margin-bottom: 0px;
		display: inline-block;
	}

	.TextRoot {
		font-size: 0.75rem;
		font-weight: normal;
		text-align: left;
		color: #4a595f;
	}

	.LeftAddSide {
		display: inline-block;
		width: 65%;

		.AddDevices1 {
			margin-bottom: 0px;
		}
	}

	.AddSite.userTemplate-addUser-conatiner {
		height: 300px;
	}

	.review-section {
		margin-bottom: 15px;
		height: calc(100vh - 250px);
		width: 100%;
		@include CardBoxBGShadow;
	}

	.AddDevices {
		height: 420px;
		background-color: $background-color_1;
		border-radius: 12px;
	}

	.DeviceTextBox {
		width: 100%;
		margin-left: 10px;
		margin-right: 10px;
		margin-top: 25px;
		@include floatLeft;
	}

	.panel-singledev {
		height: 292px;
		overflow: auto;
		border-bottom: 1px solid rgb(230, 230, 230);
	}

	.cam-device-form {
		overflow: auto;
		border-bottom: 1px solid rgb(230, 230, 230);
		height: 575px;
	}

	.alert-template-form-container {
		height: auto;
	}

	.template-btn {
		width: 6.8rem;
		@include floatRight;
	}

	.alert-template-duration-container {
		margin-right: 10px !important;

		.SiteNameTextBox {
			width: 100%;
			margin-bottom: 0px;
		}
	}

	.AddSite.userTemplate-addUser-conatiner {
		.SiteNameTextBox {
			margin-bottom: 0px;
		}
	}

	.AddSite.alertTemplate-addUpdate-container {
		.SiteNameTextBox {
			margin-bottom: 0px;
		}
	}

	#hook-form {
		.DeviceTextBox {
			.SiteNameTextBox {
				width: 100%;
				margin-left: 3.05rem;
			}
		}
	}

	.LocationInput {
		height: 2.5rem;
		border-radius: 8px;
		background-color: $background-color_3;
		border: none;
		padding-left: 5px;
		padding-right: 5px;
	}

	.BulkText {
		width: 97%;
		flex-wrap: nowrap;
		display: flex;
		align-content: center;
		align-items: center;
		justify-content: flex-start;
		margin-left: 15px;
		margin-right: 15px;
		@include floatLeft;
		margin-top: 16px;

		p {
			margin-bottom: 0px;
			font-size: 0.875rem;
		}

		.SelectionAlertViewButton {
			margin-left: 370px;
		}

		.AlertViewButton {
			margin-left: 205px;
		}
	}

	.upload-csv-button {
		width: auto;
		height: 31px;
		border-radius: 15px;
		border: 1px solid $color_10;
		background-color: $background-color_1;
		margin: 5px;
		min-width: 110px;
		padding-left: 15px;
		padding-right: 15px;
		font-size: 0.875rem !important;
	}

	.SampleTextDocument {
		margin-left: 35px;

		a {
			color: $color_11;
		}
	}

	.SiteListMain {
		height: 372px;
		margin: 10px;
		overflow: auto;
		padding-right: 8px;
		scroll-behavior: smooth;
		height: calc(100vh - 236px);

		.noRecordFoundText {
			left: 35%;
		}
		@include devices(laptop_xs) {
			height: calc(100vh - 225px);
		}
	}

	.DevicesList {
		height: 320px;
		width: 48%;
		@include floatLeft;
		background-color: $background-color_3;
	}

	.Add_Devices {
		height: 45px;
		border-bottom: 1px solid $color_9;
		width: 100%;

		p {
			font-size: 0.875rem;
			font-stretch: normal;
			text-align: left;
			color: #8a8a8a;
			font-weight: bold;
		}
	}

	.add-devices-heading {
		padding: 10px;
		background-color: $background-color_3;
	}

	.float-right {
		@include display-flex;
		align-items: center;
	}

	.DevicesListScroll {
		width: 100%;
		@include floatLeft;
		padding-bottom: 15px;
	}

	.workFlow-btn-container {
		@include display-flex;
		margin-top: 20px;
	}

	.alertTemplate-addWorkFlow-addMore-btn {
		width: 43% !important;

		.UserAddPlus {
			margin-top: 0px !important;
		}
	}

	.alertTemplate-addWorkFlow-next-btn-div {
		width: 55% !important;

		.NextMoveButton {
			margin: 0px !important;
		}
	}

	.EnableNextStep {
		width: auto;
		height: 31px;
		border-radius: 15px;
		border: none;
		background-color: $background-color_4;
		margin-top: 5px;
		padding-left: 15px;
		padding-right: 15px;

		p {
			font-size: 0.875rem;
			color: $color_2;
			margin: 0px;
			@include fontStyle;
		}
	}

	.NextMoveButton {
		height: 32px;
		border-radius: 15.5px;
		background-color: $background-color_4;
		border: none;
		line-height: 14px;
		padding-left: 15px;
		padding-right: 15px;
		width: max-content;
	}

	button.NextMoveButton.addUpdateCamera-btn {
		margin-top: 20px;
	}

	.addZoneBtn {
		height: 32px;
		border-radius: 15.5px;
		background-image: linear-gradient(244deg, #0f62acc9 26%, #0f62ac);
		border: none;
		line-height: 14px;
		padding-left: 15px;
		padding-right: 15px;
		width: max-content;
		margin-left: 0.5rem;
	}

	.addUserToSite-addMember-btn {
		margin-top: 0px !important;
	}

	.manageDevices-addPanel-btn {
		margin-top: 20px !important;
	}

	.ManageSiteGroup-NextButton {
		margin-right: 16px;
		margin-top: 20px !important;
	}

	.ManageSiteGroup-AddSelectedButton {
		margin-right: 16px;
		margin-top: 20px !important;
	}

	.assignFieldResponder-AddSelected-btn {
		margin-top: 20px !important;
	}

	.DeviceDivScroll {
		overflow-y: auto;
		padding-right: 20px;
		padding-left: 20px;
		background-color: $background-color_7;
		max-height: 615px;
	}

	.bulkDevices1 {
		display: inline-block;
		width: 50%;
	}

	.title-head {
		display: inline-flex;
		height: 1.375rem;
		margin: 1rem 0.313rem 0.5rem 0.5rem;
		font-size: 1rem;
		@include fontStyle;
		font-weight: 900;
		color: $color_4;
		align-items: center;
		justify-content: space-around;
	}

	.alertTemplate-addTemplate-description-container {
		padding: 0px 10px;
	}

	.add-template-card {
		background-color: #fff;
		margin-bottom: 15px;
		border-radius: 12px;
	}

	.config-input-section {
		margin: 0 0 0 0rem;
		padding-right: 0;
	}

	.review-btn {
		margin: 1.813rem 0.267rem 0 66rem;
		padding: 0.25rem 0.875rem 0.20rem;
		border-radius: 25px;
		box-shadow: 0 10px 16px 0 #2c79984d;
		background-image: linear-gradient(186deg, #0f688b 313%, #1a5974);
	}

	.SentenceText {
		width: 50%;
		display: inline-flex;
		@include floatLeft;
		visibility: collapse;
	}

	.ButtonText {
		width: 49%;
		display: inline-flex;
		@include floatRight;
		justify-content: flex-end;
		align-items: flex-end;
	}

	.AddDevices1 {
		background-color: #fff;
		border-radius: 12px;
		position: relative;
		height: calc(100% - 3px);
		@include floatLeft;
		width: 100%;
		box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
	}

	.BulkText1 {
		display: flex;
		align-content: center;
		align-items: center;
		justify-content: flex-start;
		margin-top: 25px;
		margin-top: 5px;

		p {
			margin-bottom: 0px;
			font-size: 0.75rem;
			display: inline-block;
		}
	}

	.AlignBulk {
		@include flexSpaceBetween;
		align-content: center;
	}

	.ButtonDiv {
		text-align: end;
	}

	.addUserToSite-btn-div {
		margin-top: 5px;
	}

	.ManageSiteDiv {
		padding-bottom: 15px;
	}

	.ManageSite.ManageSiteGroup-container {
		height: auto;
		margin-bottom: 0px;
	}

	.LeftAddSideHeight {
		height: calc(100vh - 156px);
		overflow-y: auto;
		overflow-x: hidden;
		padding-left: 5px;
		padding-right: 5px;
		padding-top: 2px;
		@include devices(laptop_xs) {
			height: calc(100vh - 135px);
		}
	}
	.LeftAddWorkHeight {
		height: calc(100vh - 145px);
		overflow-y: auto;
		overflow-x: hidden;
		padding-left: 5px;
		padding-right: 5px;
		padding-top: 2px;
	}

	.ManageSite.userTemplate-FRAssignment-container {
		height: 83vh;
		margin-bottom: 0px;
		@include devices(laptop_xl) {
			height: 82vh;
		}
		@include devices(laptop_lg) {
			height: 79vh;
		}
		@include devices(laptop_sm) {
			height: 74vh;
		}
		@include devices(laptop_xs) {
			height: calc(100vh - 155px);
		}
	}

	.SubNameSelect {
		font-size: 0.75rem;
		color: #4e4e4e;
		@include fontStyle;
	}

	.SiteGroup {
		width: 40%;
		@include floatLeft;
		background-color: $background-color_7;
	}

	.SiteList {
		width: 59%;
		@include floatLeft;
		padding-left: 20px;
		padding-right: 5px;

		.NextMoveButton.m-3 {
			margin-right: 0px;
		}
	}

	.sitegrp-sitelist {
		overflow: auto;
		// height: 62vh;
		height: 608px;
		@include devices(laptop_xl) {
            height: 578px;
        }

        @include devices(laptop_lg) {
            height: 429px;
        }

        @include devices(laptop_md) {
            // height: 63vh;
        }

        @include devices(laptop_sm) {
            height: 297px;
        }
		@include devices(laptop_xs) {
			height: calc(100vh - 325px);
		}
	}

	.user-list-item:hover {
		cursor: pointer;
		box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.08);
	}

	.review-table-body {
		height: calc(100vh - 375px) !important;
	}

	.ButtonsAttachedView1 {
		width: auto;
		@include floatRight;
		@include flexStart;
	}

	.activity-list {
		height: 270px;
		overflow-y: auto;
		scroll-behavior: smooth;
		margin-bottom: 0px;
	}

	.playback-video-image {
		padding: 5px;
		width: 15%;
		cursor: pointer;
	}

	.swal-sitegroup-warning {
		background-color: #faddde;
		border-left: 4px solid $color_12;
		border-bottom: none;
		height: 75px;
		margin-top: 5px;
	}

	.custom-title-class-site-group {
		font-size: 14px !important;
		font-weight: bold !important;
		color: black !important;
		margin-top: 10px !important;
	}

	.site-group-warning-msg {
		@include floatLeft;
		text-align: left;
		font-size: 12px;
		margin-left: 45px;
		color: $color_12;
	}

	.site-group-cancel-button {
		width: 100px;
		height: 33px;
		border-radius: 15.5px !important;
		padding: 2px !important;
		text-align: center !important;
		margin-left: 6px !important;
		display: inline-block !important;
		background-color: white !important;
		color: #236484 !important;
		border: 1px solid #236484 !important;
		border-radius: 16px !important;
		font-size: 12px !important;
	}

	.delete-site-group-confirm-button {
		width: 155px;
		color: white !important;
		background-color: #236484 !important;
	}

	.site-group-warning-head {
		@include floatLeft;
	}

	.site-group-warning-head-title {
		color: $color_12;
	}

	.custom-text-class-site-group {
		font-size: 12px !important;
		margin-top: 5px !important;
		color: #929192 !important;
	}

	.selection-list-group {
		@include flexSpaceBetween;
		height: 45px;
		align-items: center;
		border-bottom: 1px solid $color_13;
	}

	.zoneadditiontextDiv {
		text-align: -webkit-right;
	}

	.zoneTypeAdditiontext {
		color: #0f62acc9;
		cursor: pointer;
		text-align: end;
		margin-right: 60px;
		width: 123px
	}

	.zoneTypeAddButton {
		color: #0f62acc9;
		cursor: pointer;
		text-align: end;
		margin: 0px 10px 0px 10px
	}

	.zoneTypeCloseButton {
		color: #0f62acc9;
		cursor: pointer;
		text-align: end;
		margin: 0px 10px 0px 10px
	}

	.association-main-section {
		margin-top: 30px;
	}

	.association-main-section.zone-to-camera {
		margin-bottom: 14px;
	}

	.association-section-header {
		background-color: #eff5f8;
		height: 50px;
		padding-left: 20px;
		@include flexStart;
		align-items: center;

		p {
			@include fontStyle;
			font-size: 0.875rem;
			font-weight: bold;
			color: #4e4e4e;
		}
	}

	.sensors-list {
		background-color: $background-color_7;
		overflow-y: auto;
		padding-right: 20px;
		padding-left: 20px;
		height: 231px;
	}

	.panel-camera-list {
		width: 59%;
		@include floatLeft;
		padding-left: 20px;
		padding-right: 20px;
		border-bottom: 1px solid $color_13;
		height: 380px;
		overflow: auto;
		height: 231px;
	}

	.column {
		@include floatLeft;
		width: 35%;
		padding: 10px;
	}

	.column1 {
		@include floatLeft;
		width: 54%;
		padding: 10px;
	}

	.lst-header-width {
		width: 100%;
		display: flex;
		align-items: center;
	}

	.lst-header-width:nth-of-type(odd) {
		background-color: $background-color_1 !important;
	}

	.lst-header-width:nth-of-type(even) {
		background-color: #fafafa;
	}

	.lst-section-width {
		overflow: auto;
		height: 407px;
		margin-top: 5px;
	}

	.BlkUploadDiv {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	.field-list-main {
		height: "250px";
		overflow: "auto";
	}

	.no-data-found {
		text-align: center;
		padding-top: 10px;
	}

	.uploadcsv-panel {
		cursor: pointer;
	}

	.mandat-field {
		color: #ff0000;
	}

	.customplayer-label {
		color: #828c90 !important;
		font-size: 12px;
		margin-bottom: 0px !important;
	}

	.bulk-upload-msg-text {
		margin-right: 90px;
		font-family: Segoe UI;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.36;
		letter-spacing: 0.28px;
		color: #3c4043;
	}

	.mendat-fields {
		margin-left: 58px;
	}

	.manageDevices-bulkUpload-addPanels-btn {
		@include absolutePosition;
		right: 0rem;
		top: -8px;
		margin-top: 13px;
	}

	.alert-template-fullDay {
		float: left;
		width: 93%;
	}

	.alert-template-fullDay-text {
		color: #757575;
	}

	.alert-template-duration-container.col-lg-12.mt-2.row {
		margin-top: 15px !important;
		height: 2.95rem;
	}

	.reactDatePicker-container {
		display: inline-flex;
	}

	.reactDatePicker-container .dropArrow.m-1 {
		@include relativePosition;
		bottom: 36px;
		left: 102px;
	}

	.dropArrow-icon {
		@include relativePosition;
		z-index: 1000;
		left: 116px;
		bottom: 34px;
		width: 10px;
		height: 7px;
	}

	.container.form-group.mb-3 {
		margin-bottom: 6px !important;
		padding-left: 15px;
	}

	.ResizePanel-module_ResizeBarHorizontal {
		cursor: ew-resize;
		width: 20px;
		margin-left: -10px;
		margin-right: -10px;
		background: transparent;
		z-index: 10;
		-webkit-box-align: center;
		@include flexCenter;
		align-content: center;
		-webkit-box-pack: center;
		@include absolutePosition;
		top: 50%;
	}

	.ResizePanel-module_ResizeHandleHorizontal {
		cursor: ew-resize;
		width: 10px;
		height: 40px;
		background: white;
		border: 2px solid lightgray;
		border-radius: 8px;
		text-align: center;
		z-index: 10;
		overflow: hidden;
		display: -webkit-box;
		display: flex;
		-webkit-box-align: center;
		align-items: center;

		>span {
			display: inline-block;
			overflow: hidden;
			font-size: 10px;
			font-weight: bold;
			font-family: sans-serif;
			letter-spacing: 1px;
			color: #b3b3b3;
			text-shadow: 1px 0 1px rgb(90, 90, 90);
			line-height: 4px;

			&::after {
				content: ". . . . . .";
			}
		}
	}

	.EditedIcon {
		width: 15.7px;
	}

	.CustomerInfoText {
		padding-top: 5px;
	}

	.customerIconDiv {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		cursor: pointer;
	}

	.customerFilterList {
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: normal;
		text-align: left;
		border-radius: 0.25rem;
		cursor: pointer;
	}

	.CustomerSelectedText {
		width: 67px;
		height: 16px;
		font-family: Segoe UI;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: normal;
		text-align: left;
		color: #535353;
	}

	.customerFilter {
		ul {
			list-style-type: none;
			padding-left: 3px;
			margin: 0;
		}

		li {
			height: 25px;
			padding: -2px 32px 4px 4px;
			border-radius: 5px;
			margin: 5px 3px;
			padding: 0px 0px 0px 4px;

			&:hover {
				background-color: $background-color_4;
				color: #fff;
			}
		}
	}

	/* Data access edit modal popup style start */

	.selectionModel {
		min-width: 65%;

		@include devices(laptop_lg) {
			min-width: 70%;
		}

		@include devices(laptop_md) {
			min-width: 69%;
		}

		@include devices(laptop_sm) {
			min-width: 78%;
		}

		.modal-header {
			border-bottom: none;
			padding: 1rem 1rem 0rem !important;
		}

		.btn-close:focus {
			box-shadow: unset;
		}
	}

	.ModelHeaderName {
		margin: 0px;
		font-size: 17px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.29;
		letter-spacing: normal;
		text-align: left;
		color: #4b4b4b;
	}

	.countNumber {
		margin-left: 1vw;
	}

	.TwoSelectionDiv {
		width: 45%;
		border: 1px solid #868686;
		border-radius: 7px;
	}

	.SelectDiv {
		display: flex;
		justify-content: space-between;
	}

	.ApplyBtn {
		width: 120px;
		height: 40px;
		margin-bottom: 10px;
	}

	.AddRemoveBtnDiv {
		margin: 0px 10px;
		@include flexCenter;
		width: 11%;
		// @include devices(laptop_sm) {
		// 	width: 13%;
		// }
	}

	.SelectAllSearchBarDiv {
		height: 10vh;
		background-color: #f2f6fe;
		border-radius: 7px 7px 0 0;
		padding: 10px;

		@include devices(laptop_sm) {
			height: 12vh;
			padding: 8px;
		}
	}

	.HeaderwithSelectAll {
		display: flex;
		justify-content: space-between;
	}

	.HeaderwithSelectAll p {
		margin-bottom: 0.4rem !important;
	}

	.HeaderTextLabel {
		font-size: 15px;
		font-weight: 600;
		color: $color_14;
	}

	input[type=checkbox] {
		&:checked+span.AllTextwithValue {
			color: $color_14 !important;
		}
	}

	.AllTextwithValue {
		color: $color_14;
		font-size: 15px;
		font-weight: normal;
		display: inline-block;
		margin-left: 2px;
		margin-bottom: 0px;
	}

	.AllTextwithValue .devicesValue {
		font-weight: 600;
	}

	.View-in-table {
		/* width: 5vw; */
		height: 20px;
		margin: 0 0 0 0.7vw;
		font-size: 15px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: normal;
		text-align: left;
		color: #397ff5;
	}

	.SelectionListWithSelectAll {
		border: 1px solid #ccc;
		height: 40px;
		border-radius: 4px;
		padding: 5px;
	}

	.MainResultDiv {
		padding: 10px;
	}

	.HgtCheckBoxList {
		height: 32vh;
		overflow: auto;
		margin-top: 10px;
	}

	/* Data access edit modal popup style end */

	/* data access table start */
	.SelectionErrorDiv {
		@include flexCenter;
		margin-top: 50px;
	}

	.Table-data-changed-load-data-again {
		width: 193px;
		height: 16px;
		margin: 2px 150px 2px 0;
		font-family: Segoe UI;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: normal;
		text-align: left;
		color: #e30c0c;
	}

	.PaginationDiv {
		@include flexSpaceBetween;
		width: auto;
		float: right;
		height: 35px;
	}

	.pagination {
		margin-bottom: 0;
	}

	.PageNumbers {
		margin-right: 5px;
		margin-bottom: 0px;
		margin-left: 5px;
		font-size: 0.9vw;
	}

	.selectDeviceDiv {
		display: inline-block;
		align-items: center;
		width: 100%;
		justify-content: space-between;
		margin-top: 10px;
		padding: 0 7px;
	}

	.updateSelectedBtn {
		width: 230px;
		height: 40px;
		font-family: Segoe UI;
		font-size: 15px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: normal;
		text-align: left;
		color: #696969;
	}

	.DataTableView {
		thead {
			table-layout: fixed;
			background-color: #dbe6fc;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
			border-top: 0px;
			width: 100%;
			font-weight: 500;

			th {
				text-align: left;
				color: #535353;
				font-size: 15px;
				width: 12vw;
				border-top: none;
				font-family: Segoe UI;
				font-weight: 600;
				padding: 10px 10px 10px 10px;
				vertical-align: top;
			}
		}

		th {
			text-align: left;
			padding: 8px;
		}

		table {
			width: 115vw;
		}

		overflow: auto;
		width: 100%;
		padding: 10px;
		height: 49vh;
		padding-top: 0;

		td {
			font-size: 0.8vw;
			text-align: left;
			padding: 8px;
		}
	}

	/* data access table end */
	/*For data access UI fixes style start*/
	.mainDataDiv {
		padding: 5px;
		height: 75vh;
		overflow-y: auto;

		@include devices(laptop_xl) {
			height: 75vh !important;
		}

		@include devices(laptop_lg) {
			height: 70vh !important;
		}

		@include devices(laptop_md) {
			height: 70vh !important;
		}

		@include devices(laptop_sm) {
			height: 64vh !important;
		}
		@include devices(laptop_xs) {
			height: calc(100vh - 230px) !important;
		}
	}

	.nav-tabs {
		border-bottom: 2px solid $color_15;
		height: 35px;

		.nav-item.show {
			.nav-link {
				color: $color_16;
				border-color: unset;
				border-bottom: 2px solid $color_16 !important;
				border: 0;
				background-color: $background-color_1 !important;
			}
		}

		.nav-link.active {
			color: $color_16;
			border-color: $border-color_1;
			border-bottom: 2px solid $color_16 !important;
			border: 0;
			background-color: $background-color_1 !important;
		}

		.nav-link {
			font-size: 0.8rem;
			border-bottom: 0px !important;
			border-left: 0 !important;
			border-right: 0 !important;
			border-top: 0 !important;
			text-decoration: none;
			// width: 150px;
			width: auto;
			padding: 8px 10px !important;
			margin: 0px 15px;

			&:focus {
				border-color: unset !important;
			}

			&:hover {
				border-color: unset !important;
			}
			// &:hover:after {
			// 	width: 100%;
			// }
			// &:after {
			// 	position: absolute;
			// 	bottom: 0;
			// 	left: 0;
			// 	right: 0;
			// 	margin: auto;
			// 	width: 0%;
			// 	content: '.';
			// 	color: transparent;
			// 	background: #aaa;
			// 	height: 1px;
			// }
		}
	}

	.btn-primary {
		background-color: $color_16 !important;
		border: 0px !important;
	}

	.ApplyBtn {
		background-color: $color_16 !important;
	}

	.page-item.active {
		.page-link {
			color: $color_16 !important;
			background-color: transparent !important;
		}
	}

	.page-link {
		color: $color_17 !important;
		background-color: $color_16 !important;
		border: 1px solid $color_17 !important;
		text-decoration: none !important;
	}

	.page-item.active.disabled {
		.page-link {
			color: $color_16 !important;
			background-color: transparent !important;
		}
	}

	.page-item.disabled {
		.page-link {
			color: $color_17 !important;
			background-color: gray !important;
			border: 1px solid $color_17 !important;
		}
	}

	/*For data access UI fixes style end*/
	.noDataAccessMsg {
		width: auto;
		height: auto;
		border-radius: 10px;
		padding: 15px;
		box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
		border: solid 1px #c9c9c9;
		background-color: #fefaf8;
		margin: 15px 15px 0px 15px;
		display: flex;
		align-content: stretch;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
	}

	.informationIcon {
		width: 40px;
		height: 40px;
		margin: 3px 10px 4px 0;
	}

	.No-Data-Access-Group {
		width: auto;
		margin: 0 0 3px;
		font-family: Segoe UI;
		font-size: 20px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #000;
	}

	.schedulerinfomaindiv {
		padding: 10px !important;
		background-color: #FEF6F1;
		border: 1px solid #FFDFC9;
	}

	.infoorangeicon {
		height: 30px !important;
	}

	.schedulermessagetext {
		font-size: 16px !important;
	}

	.Add-or-select-data-access-group {
		width: 186px;
		height: 17px;
		margin: 3px 17px 0 0;
		font-family: Segoe UI;
		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.15;
		letter-spacing: normal;
		text-align: left;
		color: #4a4a4a;
	}

	.nodeMainDiv {
		width: auto;
		height: auto;
		max-width: 400px;
		padding: 10px 10px 10px 10px;
		border-radius: 10px;
		box-shadow: 0 0 6px 0 rgba(57, 127, 245, 0.3);
		border: solid 1px #397ff5;
		background-color: #f2f6fe;
		float: left;

		&:hover>.nodeDeleteIcon {
			display: block;
			cursor: pointer;
		}
	}

	.nodeDeleteIcon {
		display: none;
		margin-left: 5px;
	}

	.NodeDescreption span {
		font-weight: 600;
		color: #333;
	}

	.NodeTitleDescr {
		float: left;
		padding-bottom: 0.2rem;
		display: flex;
		flex-direction: column;
		min-width: 80%;
	}

	.NodeDeletemainContainer {
		width: 20px;
	}

	.react-flow__node-input {
		padding: 5px !important;
	}

	.react-flow__node-output {
		padding: 5px !important;
	}

	.react-flow__node-default {
		width: auto !important;
		height: 50px;
		max-width: 160px;
		padding: 10px 10px 10px 10px;
		border-radius: 10px;
		box-shadow: 0 0 6px 0 rgba(57, 127, 245, 0.3);
		border: solid 1px #397ff5;
		background-color: #f2f6fe;
		float: left;
		display: flex;
		flex-direction: row;
		padding: 5px !important;
	}

	.react-flow__node-default:hover>.NodeDeletemainContainer>.nodeDeleteIcon {
		display: block;
		cursor: pointer;
	}

	.onlyforEnableCheckbox {
		display: flex;
		align-items: center;
		margin-left: 8px;
	}

	.data-access-nonedit .customerInfoDiv,
	.customerInfoDiv:disabled,
	.data-access-nonedit .customerIconDiv:disabled {
		border: solid 1px #dedede !important;
		background-color: #fff !important;
		cursor: not-allowed !important;
		box-shadow: 0 0 3px 0 rgba(94, 94, 94, 0.15) !important;
	}

	.data-access-nonedit .NodeTitleDescr {
		color: #707070 !important;
	}

	.data-access-nonedit .NodeDescreption {
		color: #707070 !important;
	}

	.data-access-nonedit .react-flow__edge-path {
		stroke: #b1b1b7 !important;
	}

	.data-access-nonedit .nav-tabs .nav-link.active {
		color: #707070 !important;
		border-bottom: 2px solid #707070 !important;
	}

	.data-access-nonedit .react-flow__node-default.selected {
		border: 2px solid rgb(57, 127, 245) !important;
		background-color: rgb(242, 246, 254) !important;
	}

	.react-flow__node-default.selected {
		border: 2px solid rgb(57, 127, 245) !important;
		background-color: rgb(242, 246, 254) !important;
	}

	.dataaccessreactflow .react-flow {
		@include devices(laptop_xl) {
			height: 93% !important;
		}

		@include devices(laptop_lg) {
			height: 92% !important;
		}

		@include devices(laptop_md) {
			height: 92% !important;
		}

		@include devices(laptop_sm) {
			height: 90% !important;
		}
		@include devices(laptop_xs) {
			height: calc(100vh - 185px) !important;
		}
	}

	/* =============== */

	.progressbar {
		float: left;
		width: 100%;

		li {
			float: left;
			width: 30%;
			position: relative;
			text-align: center;

			.stepperIconContainer {
				position: absolute;
				top: 0px;
				left: 45.3%;
				height: 30px;
				width: 30px;
				border-radius: 25px;
				border: 2px solid $color_16;
				background: white;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			&:before {
				content: "";
				width: 30px;
				height: 30px;
				display: block;
				margin: 0 auto 0px auto;
				border-radius: 50%;
				line-height: 27px;
				color: $color_1;
				text-align: center;
				font-weight: bold;
				cursor: pointer;
			}

			&:after {
				content: '';
				position: absolute;
				width: 100%;
				height: 2px;
				background-color: $background-color_4;
				top: 15px;
				left: -50%;
				z-index: -1;
			}

			.progressactive {
				background: $color_16 !important;
			}

			.progressdisable {
				cursor: not-allowed !important;

				img {
					cursor: not-allowed !important;
				}
			}

			&:first-child {
				&:after {
					content: none;
				}
			}

			&::marker {
				content: none;
			}
		}

		li.active {
			+ {
				li {
					&:after {
						background-color: $background-color_4;
					}
				}
			}

			&:before {
				border-color: $color_16;
				color: $color_2;
			}
		}
	}

	.DeviceList {
		width: 75% !important;
		float: none !important;
		margin: 0 auto !important;
	}

	.DeviceListBox {
		width: 100% !important;
		border: 1px solid $color_9;
		margin: 0 auto !important;
	}

	.BulkUploadBrowseDiv {
		border: 1px solid #c7c7c7;
		height: 2.5rem;
		display: flex;
		align-items: center;
		border-radius: 5px;
		margin-left: 3px;
	}

	.BulkUploadInput {
		border: none;
		width: 87%;
		padding-left: 10px;
		font-family: Segoe UI;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.36;
		letter-spacing: 0.28px;
		color: #3c4043;
	}

	.BulkUploadTextName {
		text-align: right !important;
	}

	.noRecordFoundText {
		position: absolute;
		top: 50%;
		left: 46%;
		font-size: 0.9rem;
	}

	.ConfigureContent {
		height: calc(100vh - 160px);
		border-radius: 12px;
		background-color: #e4e9eb;
		margin: 20px 10px 20px 20px;
		padding: 12px;
	}

	.AccordionActionBgDiv {
		height: calc(100vh - 235px);
		padding: 1.375rem 0.938rem 1.313rem 0.875rem;
		border-radius: 12px;
		box-shadow: 0 2px 27px 0 rgb(0 0 0 / 6%);
		background-color: $background-color_3;
		margin-top: 15px;
		overflow: auto;
	}

	.TextConfigure {
		font-size: 0.875rem;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #000;
		margin-bottom: 0px;
		display: inline-block;
		margin: .5rem;
	}

	.ActionText {
		margin-bottom: 0px;
		font-size: 0.875rem;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #000;
		display: inline-block;
	}

	.BgDiv {
		height: 2.5rem;
		border-radius: 8px;
		background-color: #eff2f3;
		margin-top: 5px;
		padding: 10px;
		display: flex;
		align-items: center;
		justify-content: flex-start;

		p {
			font-size: 0.75rem;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: left;
			color: #000;
			margin-bottom: 0px;
		}
	}

	.del {
		width: 2.375rem !important;
		height: 2.375rem;
		margin: 0 0 0 0.5rem;
		/* padding: 0rem 0.75rem; */
		border-radius: 8px;
		background-color: $background-color_1;
		border: none;
	}

	.sensors-section {
		width: 40%;
		float: left;
	}

	.filtermaindiv {
		width: 296px;
		height: 420px;
		margin-top: 1px;
		margin-left: 1px;
		border: 0px;
		float: left;
	}

	.filterpopup {
		@include flexSpaceBetween;
		padding: 1rem 1rem;
		border-bottom: 1px solid #dee2e6;
		border-top-left-radius: calc(0.3rem - 1px);
		border-top-right-radius: calc(0.3rem - 1px);
	}

	.filterseverity {
		height: 2.5rem;
		//   border-radius: 8px;
		//   border: 1px solid #bebebe;
		//   padding-left: 8px;
		padding-right: 8px;
		font-size: 13px;
		text-align: left;
		width: 100%;
		//   margin-bottom: 15px;
	}

	.filteractivetext {
		padding: 0px;
		margin: 0px;
		line-height: 15px;
		font-size: 12px;
	}

	.filterinnermaindiv {
		// height: auto;
		// overflow: scroll;
		height: 284px;
		overflow: auto;
	}

	.AlertTemplateListDiv {
		height: 64vh;
		margin: 10px;
		overflow: auto;
		padding-right: 8px;
		scroll-behavior: smooth;

		@include devices (laptop_xxl) {
			height: 61vh;
		}

		@include devices (laptop_xl) {
			height: 63vh;
		}

		@include devices (laptop_lg) {
			height: 56vh;
		}

		@include devices (laptop_md) {
			height: 56vh;
		}

		@include devices (laptop_sm) {
			height: 48vh;
		}
		@include devices(laptop_xs) {
			height: calc(100vh - 335px);
		}
	}

	.AlertTemplateTab {
		margin: 10px;

		.nav-tabs {
			.nav-link {
				width: 110px;
			}
			// a:after {
			// 	left: 50%;
			// 	background: rgba(0, 0, 0, 0);
			// 	background: -moz-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.5) 100%);
			// 	background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.5) 100%);
			// 	background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.5) 100%);
			// 	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgba(0,0,0,0)', endColorstr='rgba(255,255,255,0.2)', GradientType=1);
			// }
			
			// a:before {
			// 	right: 50%;
			// 	background: rgba(255, 255, 255, 0.5);
			// 	background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
			// 	background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
			// 	background: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
			// 	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgba(255,255,255,0.2)', endColorstr='rgba(0,0,0,0)', GradientType=1);
			// }
			
			// a:after, a:before {
			// 	content: "";
			// 	display: block;
			// 	position: absolute;
			// 	width: 0;
			// 	height: 3px;
			// 	bottom: 0;
			// 	transition: all 0.4s ease-in 0s;
			// }
			
			// a:hover:after {
			// 	width: 50%;
			// 	left: 0;
			// }
			
			// a:hover:before {
			// 	right: 0;
			// 	width: 50%;
			// }
		}
	}

	/* Custom css for schedular in alert template start*/

	.DaySliderBox {
		// display: flex;
		display: block;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 20px 10px;
		border-bottom: 1px solid #EFEFEF;
		// overflow: hidden;
		// position: relative;
	}

	// .DaySliderBox:hover .hoverDelete {
	// 	display: block;
	// }
	// .hoverDelete {
	// 	display: none;
	// 	float: left;
	// 	position: absolute;
	// 	top: 18px;
	// 	left: -8px;
	// 	width: 85px;
	// 	height: 50px;
	// 	background-color: #00000070;
	// 	padding-top: 9px;
	// 	border-radius: 2px;
	// }
	.ScheduleBGDiv {
		width: 92%;
	}

	.SliderDiv {
		height: 29px;
		background-color: #F3F3F3;
		position: relative;
		display: flex;
		border: 1px solid #d3d3d3;
	}

	.SliderDiv>.TimeDiv {
		border-left: none;
	}

	.SliderDiv>.TimeDiv~.TimeDiv {
		border-left: 1px solid #c5c5c5;
	}

	.CustomTime {
		list-style-type: none;
		display: flex;
		padding: 0;
		margin: 0;

		li {
			width: 39px;
			text-align: left;
			font-size: 11px;
			border-left: 1px solid #bebebe;
			line-height: 10px;
			margin-top: 5px;
			padding-left: 2px;

			@include devices(laptop_xxl) {
				width: 34px;
			}

			@include devices(laptop_xl) {
				width: 32px;
			}

			@include devices(laptop_lg) {
				width: 28px;
			}

			@include devices(laptop_md) {
				width: 26px;
			}

			@include devices(laptop_sm) {
				width: 24px;
			}
			@include devices(laptop_xs) {
				width: 21px;
			}

			@include devices(mobile_desktopview) {
				width: 37px;
			}
		}
	}

	.TimeDiv {
		width: 39px;
		height: 100%;
		display: inline-block;
		border-left: 1px solid #c5c5c5;

		&:hover {
			background-color: #d4d4d4;
		}

		@include devices(laptop_xxl) {
			width: 34px;
		}

		@include devices(laptop_xl) {
			width: 32px;
		}

		@include devices(laptop_lg) {
			width: 28px;
		}

		@include devices(laptop_md) {
			width: 26px;
		}

		@include devices(laptop_sm) {
			width: 24px;
		}
		@include devices(laptop_xs) {
			width: 21px;
		}

		@include devices(mobile_desktopview) {
			width: 37px;
		}
	}

	.hgtTimeDiv {
		height: 5px;
	}

	.TimerSelectionDiv {
		float: left;
		width: 48%;
	}

	.OverlayText {
		float: left;
		position: absolute;
		top: 0px;
		display: block;
	}

	.TimingText {
		margin-bottom: 0px;
		font-size: 11px;
		color: rgb(0, 0, 0);
		font-weight: 700;
	}

	.WorkflowNameText {
		margin-bottom: 0px;
		font-size: 11px;
		color: rgb(0, 0, 0);
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		min-width: 55px;
		width: 75px;
	}

	.HiddenDiv {
		display: none;
		background-color: rgb(108, 108, 247);
		overflow: auto;
		resize: horizontal;
		float: left;
		height: 100%;
		width: 100%;
	}

	.RepTimeLabel {
		font-size: 14px;
	}

	.CheckboxDayWidthDiv {
		width: 14%;
		float: left;
	}

	.CheckboxDayWidthDiv label {
		font-weight: normal;
	}

	.CheckBoxRepTime {
		float: left;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	/* Custom css for schedular in alert template end*/

	.AlertFilter {
		position: relative;
		width: 50px;
		height: 40px;
		// padding: 8px 7.6px 7.6px 8px;
		// border-radius: 5px;
		line-height: 10px;
		// margin-left: 5px;
		background: #FFFFFF 0% 0% no-repeat padding-box;
		border: 1px solid #0B70D1;
		border-radius: 7px;
		opacity: 1;

		@include devices(laptop_xl) {
			margin-right: 10px;
		}

		@include devices(laptop_lg) {
			margin-right: 10px;
		}

		@include devices(laptop_md) {
			// height: 550px ;
		}

		@include devices(laptop_sm) {
			margin-right: 10px;
		}
	}

	.ml50 {
		margin-left: 50px !important;
	}

	.mt20 {
		margin-top: 20px !important;
	}

	.templatedevicename {
		margin-left: 15px;
		margin-top: 3px;
		float: left;
		margin-bottom: 0px !important;
		font-size: 18px;
		font-weight: 500;
	}

	.DeleteText {
		font: normal normal normal 16px/20px Segoe UI !important;
		letter-spacing: 0px;
		color: #545454;
	}
	.DeleteTextPTag {
		font: normal normal bold 16px/20px Segoe UI !important;
		letter-spacing: 0px;
		color: #545454;
	}


	.disabletogglecheckbox {
		span {
			cursor: not-allowed !important
		}
	}


	.togglecheckbox {
		label {
			position: relative;
			display: inline-block;
			width: 28px;
			height: 13px;
		}

		input {
			opacity: 0;
			width: 0;
			height: 0;
		}

		span {
			position: absolute;
			cursor: pointer;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: #c5c5c5;
			transition: 0.3s;
			border-radius: 30px;

			&::before {
				position: absolute;
				content: "";
				height: 11px;
				width: 11px;
				left: 0.6px;
				bottom: 0.7px;
				background-color: #fff;
				border-radius: 50%;
				transition: 0.3s;
			}
		}
	}

	.togglecheckbox input:checked+span {
		background-color: #0B70D1;
	}

	.togglecheckbox input:checked+span::before {
		transform: translateX(15px);
	}

	.replicateModal .modal-content {
		height: 350px;
		width: 500px;
	}

	.deleteModalClass .modal-content {
		height: 250px;
		width: 450px;
	}

	.filterinnerboxdiv {
		border: 1px solid #bebebe;
		border-radius: 10px;
		padding: 8px;
	}

	.filterBoxhgt {
		height: 160px;
		margin-left: 5px;
		margin-top: 10px;
	}

	.HgtFilterBoxList {
		height: 13vh;
		overflow: auto;
		// margin-top: 8px;
		@include devices(laptop_xl) {
			height: 14vh;
		}
		@include devices(laptop_lg) {
			height: 17vh;
		}
		@include devices(laptop_md) {
			height: 17vh;
		}
		@include devices(laptop_sm) {
            height: 20vh;
        }
	}
	// .dataAccessListItem {
	// 	margin-bottom: 0.08rem;
	// }

	.ACSCustomerFilterMainDiv {
		float: left;
		margin: 55px 0px 15px 0px !important;
		width: 100%;
	}
	.ACSTextBox {
		width: 100%;
		float: left;
		margin-top: 17px;
	}
	.filterinnerboxdiv1 {
		border: 1px solid #bebebe;
		border-radius: 10px;
		padding: 8px;
	}
	.filterModalMainclass {
		.modal-content {
			height: 655px;
			width: 850px;
			@include devices(laptop_lg) {
				height: 658px;
			}
			@include devices (laptop_md) {
				height: 585px;
			}

			@include devices (laptop_sm) {
				height: 575px;
			}
			@include devices(laptop_xs) {
				height: calc(100vh - 285px);
			}

			.modal-header {
				padding-bottom: 0px;
			}
		}
		.filterBoxhgt {
			@include devices(laptop_md) {
				height: 122px;
			}
			@include devices(laptop_sm) {
				height: 122px;
			}

			.HgtFilterBoxList {
				@include devices(laptop_md) {
					height: 12vh;
				}
				@include devices(laptop_sm) {
					height: 15vh;
				}
			}
		}
	}

	.filterbuttonsdiv {
		width: 100%;
		float: left;
		display: flex;
		text-align: center;
		justify-content: center;
		margin-top: 20px;
	}

	.filterContainer {
		float: left;
		width: 100%;
		gap: 18px;
		padding-right: 1px;

		.SiteNameText {
			color: #212529;
			font-size: 1rem;
		}
	}

	.replicateDateModal .modal-content {
		height: 470px;
		width: 700px;

		@include devices (laptop_sm) {
			height: 405px;
		}
	}

	.table-striped tbody tr:nth-of-type(odd) {
		background-color: $background-color_1 !important;
	}

	.table-striped tbody tr:nth-of-type(even) {
		background-color: #f5f5f5;
	}

	.DataTableView tbody tr:hover td {
		background-color: #f2f6fe !important;
	}

	.activityOldNewTimeText {
		font-size: 12px;
		color: #7e7e7e;
		line-height: 15px;
		height: 29px;
		padding-top: 6px;
		border: 1px solid #dbdbdb;
	}

	.activityDaysrepeatDiv {
		display: flex;
		border: 1px solid #dbdbdb;
		border-radius: 5px;

		.SliderDiv {
			height: 29px;
			border: 1px solid #dbdbdb;
		}
	}

	.activitydaysTextDiv {
		background-color: #dbdbdb;
		width: 35px;
		position: relative;
		display: inline-block;
	}

	.activitydaysText {
		font: normal normal 600 13px/15px Segoe UI;
		letter-spacing: 0px;
		color: #343434;
		position: absolute;
		top: 50%;
		left: 50%;
	}

	.CustomTime li {
		font: normal normal normal 12px/16px Segoe UI;
		letter-spacing: 0px;
		color: #000000;
	}

	.CustomTime li:first-child {
		border-left: none;
	}

	.activityLogTemplateDiv {
		display: flex;
		align-items: center;
		justify-content: space-between;
		// padding: 0px 30px;
	}

	.activitySchedulerHeaders {
		display: flex;
		width: auto;
	}

	.rotate {
		-moz-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
		-webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
		transform: translateX(-50%) translateY(-50%) rotate(-90deg);
	}

	.activityloginfomaindiv {
		background-color: #FEF6F1;
		border: 1px solid #FFDFC9;
	}

	.orangeinfoicon {
		width: 20px;
		height: 18px;
		margin: 3px 10px 4px 0;
	}

	select {
		background-image: url("../../assets/images/drop-arrow.svg");
		/*  background-image: url("./assets/images/darkTheme/dropArrowWhite.svg"); */
		background-position: calc(100% - 20px) 15px, calc(100% - 10px) 15px, 100% 0;
		background-size: 10px 7px;
		background-repeat: no-repeat;
		-webkit-appearance: none;
		-moz-appearance: none;

		:invalid {
			color: red;
		}
	}

	.OperationAccordionBgDiv {
		height: auto;
		padding: 1.375rem 0.938rem 1.313rem 0.875rem;
		border-radius: 12px;
		box-shadow: 0 2px 27px 0 rgb(0 0 0 / 6%);
		background-color: $background-color_1;
		margin-top: 15px;

		.workflow-action-input-box {
			white-space: pre-wrap;
			word-wrap: break-word;
			overflow-wrap: break-word;
			max-height: none;
			resize: none;
		}
	}

	.SelectActionP {
		font-size: 0.75rem;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #6c6c6c;
	}

	.activityLogMainDiv {
		width: 100%;
		float: left;
		// padding-left: 15px;
		padding: 0px 15px;
	}

	.AlertActivity-form {
		overflow: auto;
		overflow-x: hidden;
		// max-height: calc(100vh - 210px);
		max-height: calc(100vh - 230px);
		text-align: center;
		// padding: 0px 35px 2px;
		float: left;
		width: 100%;

		p {
			margin-bottom: 0px !important;
		}

		@include devices(laptop_xl) {
			max-height: calc(100vh - 210px);
		}

		@include devices(laptop_lg) {
			max-height: calc(100vh - 215px);
		}

		@include devices(laptop_md) {
			// height: 550px ;
		}

		@include devices(laptop_sm) {
			max-height: calc(100vh - 210px);
		}
	}

	.AuditLog-form {
		overflow: auto;
		overflow-x: hidden;
		// max-height: calc(100vh - 210px);
		text-align: center;
		// padding: 0px 35px 2px;
		float: left;
		width: 100%;
		margin-bottom: 10px;
		padding-left: 10px;

		p {
			margin-bottom: 0px !important;
		}

		// @include devices(mobile_xs) {
		// 	overflow: auto;
		// 	text-align: center;
		// 	padding: 0px 15px;
		// 	float: left;
		// 	width: 100%;
		// }
		// @include devices(mobile_s) {
		// 	overflow: auto;
		// 	text-align: center;
		// 	padding: 0px 15px;
		// 	float: left;
		// 	width: 100%;
		// }
		// @include devices(mobile_m) {
		// 	overflow: auto;
		// 	text-align: center;
		// 	padding: 0px 15px;
		// 	float: left;
		// 	width: 100%;
		// }
	}

	.ActivityLogMobileViewScheduleMainDiv {
		float: left;
		overflow: scroll;
		margin-left: 35px;
		/* margin-top: 17px; */
		margin-right: 15px;
		width: calc(100% - 45px);
		border: 1px solid rgb(219, 219, 219);
	}

	.SiteNameTextBox__control input {
		border: none !important;
		height: 38px !important;
		padding-left: 10px !important;
	}

	.MessageTextBox__control input {
		border: none !important;
		height: 38px !important;
		padding-left: 10px !important;
	}

	.SiteNameTextBox__suggestions {
		height: 150px;
		overflow: auto;
		// ul{
		// 	list-style-type: none;
		// 	padding-left: 3px;
		// 	margin: 0;
		// }
		// li{
		// 	height: 25px;
		// 	padding: -2px 32px 4px 4px;
		// 	border-radius: 5px;
		// 	margin: 5px 3px;
		// 	padding: 0px 0px 0px 4px;
		// 	:hover {
		// 		background-color: #0b70d1;
		// 		color: #fff;
		// 	}
		// }
	}

	.SiteNameTextBox__suggestions__item {
		padding: 5px 15px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);

		:hover {
			background-color: #cee4e5;
		}
	}

	.SiteNameTextBox__suggestions__list {
		background-color: white;
		border: 1px solid rgba(0, 0, 0, 0.15);
		font-size: 16px;
	}

	.user-form {
		text-align: center;
		padding: 15px 250px 0px;

		@include devices(laptop_xl) {
			padding: 15px 170px 0px;
		}

		@include devices(laptop_lg) {
			padding: 15px 150px 0px;
		}

		@include devices(laptop_md) {
			padding: 15px 110px 0px;
		}

		@include devices(laptop_sm) {
			padding: 15px 75px 0px;
		}
	}

	.inputTypeLabelContainer .formFieldLabels {
		position: absolute;
		font-size: 14px;
		font-weight: normal;
		color: #5f6368;
		background-color: #fff;
		padding: 0px 4px;
		top: -10px;
		left: 8px;
		margin: 0px !important;
		line-height: 16px;
	}

	.inputTypeLabelContainer .loginLabels {
		position: absolute;
		font-size: 14px;
		font-weight: normal;
		color: #5f6368;
		background-color: #ffffff;
		padding: 0px 4px;
		top: -10px;
		left: 8px;
		margin: 0px !important;
		line-height: 16px;
	}

	.inputType__input {
		border: 0px !important;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.long-value-input {
		// width: 365px;
		width: 625px;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		position: unset;

		@include devices(laptop_xl) {}

		@include devices(laptop_lg) {
			width: 608px;
		}

		@include devices(laptop_md) {
			width: 580px;
		}

		@include devices(laptop_sm) {
			width: 595px;
		}
	}

	.AddCustomerModal .modal-content {
		width: 650px;
	}

	.bs-popover-right {
		// transform: translate(1090px, 207px) !important;
		margin-left: -175.5px !important;
	}

	.PrepostSettingForm {
		overflow: auto;
		overflow-x: hidden;
		max-height: calc(100vh - 185px);
		text-align: center;
		float: left;
		width: 100%;
		padding: 0px 200px;
		@include devices(laptop_xs) {
			padding: 0px 145px;
		}
	}

	.PrePostSettingDiv {
		height: 950px;

		@include devices(laptop_lg) {
			height: 585px;
		}

		@include devices(laptop_md) {
			height: 580px;
		}
		@include devices(laptop_xs) {
			height: calc(100vh - 191px);
		}
	}

	.prepostalerttype {
		.dropdown-container {
			border-radius: 0px !important;
			height: 0rem !important;
			border: none !important;
			background-color: transparent !important;
		}

		.dropdown-container:focus-within {
			box-shadow: none;
		}
	}

	.hgtdropdownfield {
		height: 35px;
		display: block;
	}

	.prepostinputwidth {
		width: calc(100% - 115px) !important;
	}

	.prepostCustomername {
		margin: 0;
		margin-top: 20px;
		top: 150px;
		left: 852px;
		text-align: left;
		font: normal normal normal 24px / 32px Segoe UI;
	}

	.input-group-text {
		line-height: 1.45 !important;
	}

	.paddingLR300 {
		padding: 0px 300px !important;
		@include devices(laptop_xs) {
			padding: 0px 255px !important;
		}
	}

	/* Style for termsandcondition page */
	.termsContainer {
		height: auto;
		padding: 20px 15%;
	}

	.termsContainerAfteLogin {
		height: 100%;
		width: calc(100% - 20px);
		background-color: #FFFFFF;
		// padding: 20px 15%;
	}

	.hgtTermsContainerBorder {
		border: 1px solid #d3d3d3;
		border-radius: 5px;
	}

	.hgtTermsContainer {
		height: 650px;
		overflow: auto;
		padding: 15px;

		@include devices(laptop_xxl) {
			height: 590px;
		}

		@include devices(laptop_xl) {
			height: 625px;
		}

		@include devices(laptop_lg) {
			/* height: 495px ;100% */
			height: 475px;
			/* 125% */
		}

		@include devices(laptop_md) {
			height: 475px;
		}

		@include devices(laptop_sm) {
			height: 345px;
		}
	}

	.acceptliecenceBorder {
		border-top: 1px solid #d3d3d3;
		padding-top: 5px;
		padding-bottom: 5px;
		height: 45px;
	}

	.DateAgreeText {
		margin-bottom: 0;
		font-size: 19px;
		font-weight: 600;
		padding: 5px 15px;
	}

	.termsAcceptButton {
		text-align: center;

		button {
			width: 100px;
			color: #fff;
		}
	}

	/* Style for termsandcondition page end*/
	.mt300 {
		margin-top: 300px !important;

		@include devices(laptop_sm) {
			margin-top: 170px !important;
		}
	}

	.minWidth150 {
		min-width: 150px;

		@include devices(laptop_sm) {
			min-width: 120px !important;
		}
	}

	// panel setting page start

	.PanelControlDiv {}

	.PanelDeviceTabBody {
		// height: 565px;
		border: 1px solid gray;
	}

	.PanelHeading {
		p {
			font: normal normal 600 16px / 21px Segoe UI;
			color: #433F3F;
		}
	}

	.InputDeviceSubTabsBody {
		background: #F8F8F8;
		height: 100%;

		.checkboxLabel {
			// margin: 10px 20px 0px 0px;
			display: block;
			float: right;
			width: 100%;
			padding: 5px 15px 0px 0px;
			text-align: right;
		}
	}

	.PanelDetailMain {
		display: inline-flex;
		vertical-align: middle;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		flex-wrap: wrap;
	}

	.PanelDetailDiv {
		margin-right: 20px;
		margin-bottom: 5px;
		span {
			text-align: left;
			// font: normal normal normal 12px/14px Arial;
			letter-spacing: 0px;
			color: #484848;
		}
		p:first-child {
			font-size: 0.865rem;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #433F3F;
			margin-bottom: 7px;
			margin: 0px;
		}
		
		p:last-child {
			font-size: 0.800rem;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: left;
			color: #0e6689;
			margin-bottom: 0px;
			/* width: 225px; */
			width: auto;
			max-width: 300px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
	
			@include devices(laptop_xl) {
				/* width: 150px; */
				width: auto;
			}
	
			@include devices(laptop_lg) {
				/* width: 141px; */
				width: auto;
			}
	
			@include devices(laptop_md) {
				/* width: 122px; */
				width: auto;
			}
	
			@include devices(laptop_sm) {
				width: 113px;
			}
		}	
	} 


	.main-section {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		overflow: auto;
		height: 43.5vh;
		scroll-behavior: smooth;
		float: left;
		align-content: flex-start;
		width: 100%;

		.card {
			width: var(--cardWidth);
			height: var(--cardHeight);
			margin: var(--cardMargin);
			border: 1px solid #ccc;
			border-radius: 5px;
			background: #fff;
			box-sizing: border-box;
			box-shadow: 0px 0px 6px #0000004D;
			justify-content: center;
		}

		.zoneCheckboxs {
			width: var(--cardZoneWidth);
			height: var(--cardZoneHeigth);
			margin: var(--cardZoneMargin);
			display: flex;
			align-items: center;
			justify-content: space-around;
		}

		@include devices(laptop_xl) {
			height: 41.5vh;
		}

		@include devices(laptop_lg) {
			height: 31vh;
		}

		@include devices(laptop_md) {
			height: 31vh;
		}

		@include devices(laptop_sm) {
			height: 17vh;
		}
	}

	.outputdevices-section {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		overflow: auto;
		height: 56vh;
		scroll-behavior: smooth;
		float: left;
		align-content: flex-start;
		width: 100%;

		.card {
			width: var(--cardWidth);
			height: var(--cardHeight);
			margin: var(--cardMargin);
			border: 1px solid #ccc;
			border-radius: 5px;
			background: #fff;
			box-sizing: border-box;
			box-shadow: 0px 0px 6px #0000004D;
			justify-content: center;
		}

		.zoneCheckboxs {
			width: var(--cardZoneWidth);
			height: var(--cardZoneHeigth);
			margin: var(--cardZoneMargin);
		}

		@include devices(laptop_xl) {
			height: 54vh;
		}

		@include devices(laptop_lg) {
			height: 46vh;
		}

		@include devices(laptop_md) {
			height: 46vh;
		}

		@include devices(laptop_sm) {
			height: 34vh;
		}
	}

	.connectButton {
		height: 40px !important;
	}

	.cardCheckbox {
		text-align: right;
		margin: 0px 10px 0px 0px;
	}

	.PanelList {
		height: 720px !important;

		@include devices(laptop_xl) {
			height: 690px !important;
		}

		@include devices(laptop_lg) {
			/* height: 495px ;100% */
			height: 540px !important;
			/* 125% */
		}

		@include devices(laptop_md) {
			height: 540px !important;
		}

		@include devices(laptop_sm) {
			height: 410px !important;
		}
		@include devices(laptop_xs) {
			height: calc(100vh - 225px) !important;
		}
	}

	.Error_Message {
		margin: 0px;
		color: #e11e24;
		font-size: 14px;
		margin-top: 2px;
		text-align: center;
	}

	.Success_Message {
		margin: 0px;
		color: #00AF07;
		font-size: 14px;
		margin-top: 2px;
		text-align: center;
	}

	.hoeizontalLine {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
		border: 0;
		border-top: 1px solid rgba(0, 0, 0, .1);
	}
	.soundContainer {
		width: 100%;
		// margin-left: 15px;
		position: relative;
		float: left;
		background-color: #ffffff;
		border-radius: 13px;
		box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
	}
	/* .cameraFilterPage {
		height: calc(100vh - 140px);
	} */
	.panelFilterModalClass {
		.modal-content {
			height: 385px;
			width: 850px;

			@include devices (laptop_md) {
				height: 390px;
			}

			@include devices (laptop_sm) {
				height: 390px;
			}
			@include devices(laptop_xs) {
				height: 370px;
			}

			.modal-header {
				padding-bottom: 0px;
			}
		}		
	}

	#cta{
		width:100%; cursor: pointer; position: absolute;
	}
	
	#cta .arrow{left: 6%;}
	.arrow {position: absolute; bottom: 0;  margin-left:0px; width: 12px; height: 12px; background-size: contain;}
	.segunda{margin-left: 8px;}
	.next {
		background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHN0eWxlPi5zdDB7ZmlsbDojZmZmfTwvc3R5bGU+PHBhdGggY2xhc3M9InN0MCIgZD0iTTMxOS4xIDIxN2MyMC4yIDIwLjIgMTkuOSA1My4yLS42IDczLjdzLTUzLjUgMjAuOC03My43LjZsLTE5MC0xOTBjLTIwLjEtMjAuMi0xOS44LTUzLjIuNy03My43UzEwOSA2LjggMTI5LjEgMjdsMTkwIDE5MHoiLz48cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzE5LjEgMjkwLjVjMjAuMi0yMC4yIDE5LjktNTMuMi0uNi03My43cy01My41LTIwLjgtNzMuNy0uNmwtMTkwIDE5MGMtMjAuMiAyMC4yLTE5LjkgNTMuMi42IDczLjdzNTMuNSAyMC44IDczLjcuNmwxOTAtMTkweiIvPjwvc3ZnPg==);
	}
	
	@keyframes bounceAlpha {
	  0% {opacity: 1; transform: translateX(0px) scale(1);}
	  25%{opacity: 0; transform:translateX(10px) scale(0.9);}
	  26%{opacity: 0; transform:translateX(-10px) scale(0.9);}
	  55% {opacity: 1; transform: translateX(0px) scale(1);}
	}
	
	.bounceAlpha {
		animation-name: bounceAlpha;
		animation-duration:1.4s;
		animation-iteration-count:infinite;
		animation-timing-function:linear;
	}
	
	.arrow.primera.bounceAlpha {
		animation-name: bounceAlpha;
		animation-duration:1.4s;
		animation-delay:0.2s;
		animation-iteration-count:infinite;
		animation-timing-function:linear;
	}
	
	.round:hover .arrow{
		animation-name: bounceAlpha;
		animation-duration:1.4s;
		animation-iteration-count:infinite;
		animation-timing-function:linear;
	}
	.round:hover .arrow.primera{
		animation-name: bounceAlpha;
		animation-duration:1.4s;
		animation-delay:0.2s;
		animation-iteration-count:infinite;
		animation-timing-function:linear;
	}
}