

@mixin devices ($breakpoint) {  
    @if $breakpoint == laptop_xxl {    
        @media only screen and (max-width: 1900px) and (min-width: 1701px){
            @content;
        }
    }
    @if $breakpoint == laptop_xl {    
        @media only screen and (max-width: 1700px) {
            @content;
        }
    }
    @if $breakpoint == laptop_lg {    
        @media only screen and (max-width: 1600px) {
            @content;
        }
    }
 
    @if $breakpoint == laptop_md {
        @media only screen and (max-width: 1440px) {
            @content;
        }
    }

    @if $breakpoint == laptop_sm {
        @media only screen and (max-width: 1366px) {
            @content;
        }
    }

    @if $breakpoint == laptop_xs {
        @media only screen and (max-width: 1280px) {
            @content;
        }
    }

    @if $breakpoint == mobile_desktopview {
        @media only screen and (max-width: 1080px) {
            @content;
        }
    }


    @if $breakpoint == mobile_xs {
        @media only screen and (max-width: 480px) {
            @content;
        }
    }
    @if $breakpoint == mobile_s {
        @media only screen and (max-width: 768px) {
            @content;
        }
    }
    @if $breakpoint == mobile_m {
        @media only screen and (max-width: 992px) {
            @content;
        }
    }
    @if $breakpoint == mobile_l {
        @media only screen and (max-width: 1200px) {
            @content;
        }
    }
}